<template>
  <div id="menu" class="c-menu">
    <div id="menuCard" class="menu-card">

      <div class="backdrop"></div>

      <div id="menuContent" class="content">
        <div class="menu-title">{{ $store.getters.copy('app_title') }}</div>
        <div class="links">
          <router-link to="/" @click.native="closeMenu()">{{ $store.getters.copy('button_opnieuw') }}</router-link>
        </div>
        <div class="db-title">Denkboek.nl</div>
      </div>

    </div>

    <div id="menuBtn" class="menuBtnContainer" @click="menuHandler">
      <div class="menu-btn"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Nav",

  components: {},

  methods: {
    /**
     * Menu open and close handler
     */
    menuHandler() {
      const menu = document.getElementById("menu");
      if (menu.classList.contains("open")) {
        this.closeMenu();
      } else {
        menu.classList.add("open");
      }
    },

    /**
     * Closes the menu and submenu's
     */
    closeMenu() {
      const menu = document.getElementById("menu");
      if (menu.classList.contains("open")) {
        menu.classList.remove("open");
      }
      this.closeSubmenus();
    },

    /**
     * Closes the submenu's
     */
    closeSubmenus() {
      const menu = document.getElementById("menu");
      menu.querySelectorAll('.c-sub-menu').forEach(el => el.classList.remove('show'))
    },
  },

  created() {
    /**
     * Event for the menu clicks
     */
    const wpMenuClickHandler = (event) => {
      // if it's a submenu toggle
      if (event.target.dataset.isToggle) {
        // do not register click (wordpress does '#')
        event.preventDefault();
        // grab this one's submenu
        const submenuEl = event.currentTarget.querySelector('.c-sub-menu');
        // toggle
        !submenuEl || ( submenuEl.classList.contains('show') ? submenuEl.classList.remove('show') : submenuEl.classList.add('show') )
      }
    }

    // build request 
    const request = new XMLHttpRequest();
    // request onload
    request.onload = (response) => {
      // add menu html from Wordpress
      document.getElementById('menuContent').innerHTML += response.currentTarget.responseText;
      // add submenu interaction 
      document.querySelectorAll('.menu-item-has-children').forEach((el) => {
        // set toggle flag for all root anchors that have children
        el.querySelector('a').dataset.isToggle = true;
        // capture clicks
        el.addEventListener('click', wpMenuClickHandler)
      });
    }
    // load html from Wordpress 
    request.open("get", (window.NAV_URL || process.env.VUE_APP_NAV_URL), true);
    request.send();
  },
};
</script>

<style lang="scss">
@import "../scss/nav";
</style>