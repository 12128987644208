<template>
  <div class="layer ritueel-subselector">
    <div class="backdrop"></div>

    <div ref="scherm1" v-if="state === SCHERM1" class="ritueel-scherm1">
      <div class="content" @click="screenClickHandler">
        <!-- <div class="type-label">ritueel</div> -->
        <div class="rit-label">{{ $store.getters.rituelen()[this.ritueelType].titel }}</div>
        <!-- <hr> -->
        <div class="rit-subtitel" v-html="$store.getters.rituelen()[this.ritueelType].subtitel"></div>
        <div class="rituelen-display">
          <RitueelItem v-for="ritueel in ritueelList" :key="ritueel.id" :isDisplay="true" :ritueel="ritueel"/>
        </div>
        <nextSVG class="next"/>
      </div>
    </div>

    <div ref="scherm2" v-if="state === SCHERM2" class="ritueel-scherm2">
      <div class="content" @click="screenClickHandler">
        <div v-html="$store.getters.rituelen()[this.ritueelType].intro_text"></div>
        <nextSVG class="next"/>
        <div v-if="$store.getters.rituelen()[this.ritueelType].omschrijving && $store.getters.rituelen()[this.ritueelType].omschrijving !== ''" class="omschrijving" v-html="$store.getters.rituelen()[this.ritueelType].omschrijving"></div>
      </div>
    </div>

    <div ref="scherm3" v-if="state === SCHERM3" class="ritueel-scherm3">
      <div class="content" @click="screenClickHandler">
        <div v-html="ritueelCTA"></div>
        <nextSVG class="next"/>
      </div>
    </div>

    <div ref="rituelen" v-if="state === SELECTIE && ritueelList" class="rituelen">
      <RitueelItem v-for="ritueel in ritueelList" :key="ritueel.id" :ritueel="ritueel" @selectRitueel="selectRitueelHandler" @playRitueel="playRitueelHandler"/>
    </div>

    <div v-if="state === SELECTIE && ritueelList" class="skipRitueelButtonContainer">
      <div class="skipRitueelButton" @click="skip">{{ $store.getters.copy('skip_ritueel_tekst') }}</div>
    </div>

    <div class="closeButContainer">
      <div :class="`close ${state}`" @click="close"></div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/util/arrow';

import RitueelItem from '@/components/RitueelItem';
import nextSVG from '@/assets/next2.svg?inline';

export default {
  name: "RitueelSubSelector",

  props: {
    monument: Object,
  },

  components: {
    RitueelItem,
    nextSVG,
  },

  data() {
    return {
      SCHERM1: "scherm1",
      SCHERM2: "scherm2",
      SCHERM3: "scherm3",
      SELECTIE: "selectie",
      state: null,
      ritueelList: null,
      ritueelType: null,
      hasOmschrijving: false,
      arrow: null,
    };
  },

  computed:{
    ritueelCTA() {
      return this.$store.getters.rituelen()[this.ritueelType].cta_text.replace("{{VERHAAL_TITEL}}", `<span class="em">${this.monument.title}</em>`);
    }
  },

  methods: {
    open(ritueelType) {
      if (!this.monument[`${ritueelType}_list`]) {
        console.warn(`${ritueelType}_list not found in monument`);
        return;
      }

      this.ritueelType = ritueelType;

      this.ritueelList = this.monument[`${ritueelType}_list`];

      this.hasOmschrijving = this.$store.getters.rituelen()[this.ritueelType].omschrijving && this.$store.getters.rituelen()[this.ritueelType].omschrijving !== '';

      // console.log(this.$store.getters.rituelen()[this.ritueelType]);

      this.state = this.SCHERM1;
      setTimeout(() => this.$refs.scherm1.classList.add("show"), 20);

      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    close() {
      this.$el.classList.remove("show");

      this.removeArrow();

      this.ritueelList = null;

      this.$emit("closed");
    },

    skip() {
      this.$emit("skipRitueel", this.ritueelType);
    },

    screenClickHandler(event) {
      console.log('screenClickHandler', this.state)
      this.$emit("screenClick");
      switch (this.state) {
        case this.SCHERM1:
          this.$refs.scherm1.classList.remove("show");
          setTimeout(() => {
            this.state = this.SCHERM2;
            setTimeout(() => {
              if (!this.arrow && this.$store.getters.rituelen()[this.ritueelType].omschrijving) {
                this.arrow = new Arrow(200, 100);
                this.$refs.scherm2.querySelector('.content').appendChild(this.arrow);
              }
              this.$refs.scherm2.classList.add("show")
            }, 20);
          }, 200);
          break;

        case this.SCHERM2:
          this.$refs.scherm2.classList.remove("show");
          this.removeArrow();
          setTimeout(() => {
            this.state = this.SCHERM3;
            setTimeout(() => this.$refs.scherm3.classList.add("show"), 20);
          }, 200);
          break;

        case this.SCHERM3:
          this.$refs.scherm3.classList.remove("show");
          setTimeout(() => {
            this.state = this.SELECTIE;
            setTimeout(() => this.$refs.rituelen.classList.add("show"), 20);
          }, 200);
          break;
      }
    },

    selectRitueelHandler(ritueel) {
      this.$emit("selectRitueel", ritueel);
    },

    playRitueelHandler(ritueel) {
      this.$emit("playRitueel", ritueel);
    },

    removeArrow() {
      if (this.arrow) {
        this.$refs.scherm2.querySelector('.content').removeChild(this.arrow);
        this.arrow = null;
      }
    },
  },

  mounted() {
  }
};
</script>

<style lang="scss">
@import "../scss/ritueel-subselector";
</style>