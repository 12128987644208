<template>
  <div class="edit-bar">
    <!-- <div class="edit-btn selected" @click="verhaalHandler"> -->
    <div class="edit-btn selected">
      <span class="icon">{{ $store.getters.copy('editbar_verhaal_titel') }}</span>
      <span class="dot"></span>
      <checkmarkSVG class="checkmark"/>
      <span>{{ $store.getters.copy('editbar_verhaal_label') }}</span>
    </div>
    <div v-for="(ritueel, index) in rituelen" :key="index" :class="`edit-btn ${ritClass(ritueel.type)}`" @click="clickHandler" :data-type="ritueel.type">
      <span class="icon">
        <gedichtSVG v-if="ritueel.type === `gedicht`" />
        <muziekSVG v-if="ritueel.type === `muziek`" />
        <stilteSVG v-if="ritueel.type === `stilte`" />
        <wilhelmusSVG v-if="ritueel.type === `wilhelmus`" />
        <bloemenSVG v-if="ritueel.type === `neerleggen`" />
      </span>
      <span class="dot"></span>
      <checkmarkSVG class="checkmark"/>
      <skipSVG class="skipped"/>
      <span>{{ ritueel.titel }}</span>
    </div>
    <div :class="`edit-btn ${bekijkClass}`" @click="bekijkHandler">
      <span class="icon permanent">{{ $store.getters.copy('editbar_bekijk_titel') }}</span>
      <span class="dot"></span>
      <playSVG class="play"/>
      <span>{{ $store.getters.copy('editbar_bekijk_label') }}</span>
    </div>
  </div>
</template>

<script>
import gedichtSVG from "@/assets/gedicht.svg?inline";
import muziekSVG from "@/assets/muziek.svg?inline";
import stilteSVG from "@/assets/stilte.svg?inline";
import wilhelmusSVG from "@/assets/wilhelmus.svg?inline";
import bloemenSVG from "@/assets/bloemen.svg?inline";
import checkmarkSVG from "@/assets/checkmark.svg?inline";
import skipSVG from "@/assets/close-grey.svg?inline";
import playSVG from "@/assets/play.svg?inline";

export default {
  name: "EditBar",

  components: {
    gedichtSVG,
    muziekSVG,
    stilteSVG,
    wilhelmusSVG,
    bloemenSVG,
    checkmarkSVG,
    playSVG,
    skipSVG,
  },

  data() {
    // create button list with copy from store
    const rituelen = [];
    for (const [type, ritueel] of Object.entries(this.$store.getters.rituelen())) {
      rituelen.push({ type, titel: ritueel.titel });
    }

    return {
      rituelen,
      selectedRitueelTypes: [],
      skippedRitueelTypes: [],
    };
  },

  computed:{
    bekijkClass() {
      return this.allSelected() ? 'selected' : '';
    }
  },

  methods: {

    allSelected() {
      return this.selectedRitueelTypes.length + this.skippedRitueelTypes.length == Object.entries(this.$store.getters.rituelen()).length;
      // // create array for difference
      // const rituelen = [];
      // for (const [type, ritueel] of Object.entries(this.$store.getters.rituelen())) {
      //   rituelen.push(type);
      // }
      // // difference
      // const diff = rituelen.filter(x => !this.selectedRitueelTypes.includes(x));
      
      // console.log(Object.entries(this.$store.getters.rituelen()).length, this.selectedRitueelTypes.length, this.skippedRitueelTypes.length);

      // return this.selectedRitueelTypes.length > 0 && diff.length === 0;
    },

    show() {
      this.$el.classList.add("show");
    },

    // verhaalHandler(event) {
    //   this.$emit("clickedRDisplay", { type: "verhaal" });
    // },

    clickHandler(event) {
      this.$emit("clickedRDisplay", { type: event.currentTarget.dataset.type });
    },

    bekijkHandler() {
      this.$emit("clickedNextStep");
    },

    addSelectedRitueelType(type) {
      // remove skipped 
      this.skippedRitueelTypes = this.skippedRitueelTypes.filter((_type) => _type !== type);
      // find or add
      this.selectedRitueelTypes.find((_type) => _type === type) ||
        this.selectedRitueelTypes.push(type);
    },

    addSkippedRitueelType(type) {
      // remove selected 
      this.selectedRitueelTypes = this.selectedRitueelTypes.filter((_type) => _type !== type);
      // find or add
      this.skippedRitueelTypes.find((_type) => _type === type) ||
        this.skippedRitueelTypes.push(type);
    },

    ritClass(type) {
      // console.log('ritClass', type, this.selectedRitueelTypes.find(_type => _type === type) === type)
      return (this.selectedRitueelTypes.find((_type) => _type === type) === type
        ? "selected"
        : "") + " " + (this.skippedRitueelTypes.find((_type) => _type === type) === type
        ? "skipped"
        : "");
    },

    setBlur(flag) {
      if (flag && flag === true) {
        setTimeout(() => this.$el.classList.add("blur"), 210);
      } else if (!flag || flag === false) {
        this.$el.classList.remove("blur");
      }
    },
  },
};
</script>