import * as THREE from "three";

class ImageLayer {

    constructor(radius, height, src, repeatX, repeatY, offsetX, offsetY, transparent) {
        if (!radius) { radius = 100; }
        if (!height) { height = 5000; }
        if (!repeatX) { repeatX = 16; }
        if (!repeatY) { repeatY = 1; }
        if (!offsetX) { offsetX = 0; }
        if (!offsetY) { offsetY = 0; }
        if (typeof transparent === "undefined") { transparent = true; }

        // prep material
        const material = new THREE.MeshBasicMaterial({ transparent: transparent, side: THREE.BackSide });
        const materials = [
            material,
            // new THREE.MeshBasicMaterial({color: '#ff00ff', side: THREE.BackSide }),
            // new THREE.MeshBasicMaterial({color: '#00ffff', side: THREE.BackSide }),
            new THREE.MeshBasicMaterial({}),
            new THREE.MeshBasicMaterial({}),
        ];

        // dome
        let geometry = new THREE.CylinderGeometry(radius, radius, height, 24);
        let mesh = new THREE.Mesh(geometry, materials);

        // texture
        const loader = new THREE.TextureLoader();
        loader.load(src,
            (texture) => {
                texture.format = THREE.RGBAFormat;
                // texture.magFilter = THREE.LinearFilter;
                texture.wrapS = THREE.RepeatWrapping;
                // texture.wrapT = THREE.RepeatWrapping;
                texture.offset.set(offsetX, offsetY);
                texture.repeat.set(repeatX, repeatY);

                material.map = texture;

                texture.needsUpdate = true;
                material.needsUpdate = true;
            }
        );

        return mesh;
    }
}

export default ImageLayer;