import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from '../views/Intro.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'intro',
    component: Intro
  },
  {
    path: '/player/:doecode',
    name: 'player',
    component: () => import(/* webpackChunkName: "player" */ '../views/Player.vue') // separate chunk
  },
  {
    path: '/editor/:id',
    name: 'editor',
    component: () => import(/* webpackChunkName: "editor" */ '../views/Editor.vue') // separate chunk
  },
]

const router = new VueRouter({
  mode: 'history',
  base: window.PUBLIC_PATH || process.env.BASE_URL,
  routes
})

export default router
