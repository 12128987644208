<template>
  <div class="layer player-intro">
    <div v-if="monument" class="visual" :style="`background-image: url(${monument.afbeelding.url})`" @click="nextStep">
      <div class="inner" v-html="monument.player_intro_text"></div>
      <playSVG class="play"/>
    </div>
  </div>
</template>

<script>
import playSVG from "@/assets/play3.svg?inline";

export default {
  name: "PlayerIntro",

  components: {
    playSVG,
  },

  props:{
    monument: Object,
  },

  data() {
    return {};
  },

  computed: {
  },

  methods: {
    show() {
      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    hide() {
      this.$el.classList.remove("show");

      this.$emit("closed");
    },

    nextStep() {
      this.$emit("clicked");
    },
  },

  mounted() {
  }
};
</script>