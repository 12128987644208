<template>
  <div class="rope-container">
    <canvas id="scene" width="200" height="600"></canvas>
    <div id="handle" class="handle"></div>
  </div>
</template>

<script>
const steps = 45;

export default {
  name: "Rope",

  components: {
  },

  data() {
    return {
      ctx: null,
      points: [],
      numPoints: 0,
      handle: null,
      pulling: false,
      mouse: {x:0, y:0},
      prev_mouse: {x:0, y:0},
      active: true,
      ropeNudgeTime: 15 * 60, // s * fps
      tickCounter: 0,
    };
  },

  methods: {
    motion() {
      const points = this.points;
      for (let i = 1; i < points.length; ++i) {
        let newX = points[i].x + (points[i].x - points[i].lx);
        let newY = points[i].y + (points[i].y - points[i].ly) + 0.6;
        points[i].lx = points[i].x;
        points[i].ly = points[i].y;
        points[i].x = newX;
        points[i].y = newY;
      }
    },

    constraint() {
      const points = this.points;
      let iterations = 50;
      while (iterations--)
        for (let i = 1; i < points.length; ++i) {
          if (i >= 1) {
            let distX = points[i].x - points[i - 1].x;
            let distY = points[i].y - points[i - 1].y;
            let distance = Math.sqrt(distX * distX + distY * distY);
            let distCompX = distX / distance;
            let distCompY = distY / distance;
            let distDeviation = (distance - points[i].distFromParent) / 2;

            let correctionX = distDeviation * distCompX;
            let correctionY = distDeviation * distCompY;

            if (!points[i - 1].fixed) {
              points[i - 1].x += correctionX;
              points[i - 1].y += correctionY;
            }
            if (!points[i].fixed) {
              points[i].x -= correctionX;
              points[i].y -= correctionY;
            }
          }

          if (points[i].x < 0) points[i].x = 0;
          if (points[i].y < 0) points[i].y = 0;
          if (points[i].x > 200) points[i].x = 200;
          if (points[i].y > window.innerHeight) points[i].y = window.innerHeight;
        }
  
        if (this.pulling) {
        let rect = this.$el.getBoundingClientRect();
          points[this.numPoints - 1].x = this.mouse.x - rect.left;
          points[this.numPoints - 1].y = this.mouse.y - rect.top;
        }

    },

    render() {
      const points = this.points;

      this.ctx.clearRect(0, 0, 200, 600);

      this.ctx.strokeStyle = "#ED1D24";

      this.ctx.beginPath();

      this.ctx.moveTo(points[0].x, points[0].y);
      
      for (let i = 1; i < points.length; ++i) {
        this.ctx.lineTo(points[i].x, points[i].y);
      }

      let xpos, ypos;
      if (!this.pulling) {
        xpos = points[this.numPoints - 1].x - 13;
        ypos = points[this.numPoints - 1].y - 13;
      } else {
        let rect = this.$el.getBoundingClientRect();
        xpos = this.mouse.x - rect.left - 13;
        ypos = this.mouse.y - rect.top - 13;
        // console.log(this.mouse.x, this.mouse.y)
      }
      this.handle.style.transform = `translate(${xpos}px, ${ypos}px)`;

      this.ctx.stroke();
    },

    tick() {
      if (!this.active) {
        return;
      }

      requestAnimationFrame(this.tick.bind(this));

      this.tickCounter++;

      // nudge
      if (this.tickCounter%this.ropeNudgeTime === 0) {
        const r = 1 + Math.floor(Math.random() * 0.6 * this.numPoints);
        // console.log(r, this.numPoints, this.points[r])
        this.points[r].x = 100 + (Math.random() * 20 - 10);
        this.tickCounter = 1;
      }

      const dx = this.mouse.x - this.prev_mouse.x;
      const dy = this.mouse.y - this.prev_mouse.y;
      if (this.pulling && dx * dx + dy * dy > 50) {
        this.activate();
      }

      this.prev_mouse = {x:this.mouse.x, y:this.mouse.y};

      this.motion();
      this.constraint();
      this.render();
    },

    deactivate() {
      this.active = false;
    },

    start() {
      this.tick();
      this.$el.classList.add('show');
    },

    pullstart() {
      // console.log('pullstart');
      this.pulling = true;
    },

    move(event) {
      if (event.type === 'touchmove') {
        this.mouse = {x:event.changedTouches[0].pageX, y:event.changedTouches[0].pageY};
      } else {
        this.mouse = {x:event.clientX, y:event.clientY};
      }
    },

    pullend() {
      // console.log('pullend')
      this.pulling = false;
    },

    hide() {
      this.$el.classList.remove('show');
      this.$el.classList.add('hide');
    },

    activate() {
      this.pullend();
      // this.points[0].fixed = false;

      this.handle.removeEventListener("touchstart", this.pullstart.bind(this));
      this.handle.removeEventListener("mousedown", this.pullstart.bind(this));

      this.handle.removeEventListener("touchend", this.pullend.bind(this));
      this.handle.removeEventListener("mouseup", this.pullend.bind(this));

      this.$el.removeEventListener("touchmove", this.move.bind(this));
      this.$el.removeEventListener("mousemove", this.move.bind(this));

      this.$emit('pulled');
      // document.getElementById("scene").height = window.innerHeight + 40;
      // document.getElementById("scene").style.height = `${window.innerHeight + 40}px`;
    },
  },

  mounted() {

    document.getElementById("scene").height = 600;
    document.getElementById("scene").style.height = `600px`;

    this.ctx = document.getElementById("scene").getContext("2d");

    this.ctx.lineWidth  = 6;
    this.ctx.lineCap    = "round";
    this.ctx.lineJoin   = "round";

    this.handle = document.getElementById("handle");

    this.handle.addEventListener("touchstart", this.pullstart.bind(this));
    this.handle.addEventListener("mousedown", this.pullstart.bind(this));

    this.handle.addEventListener("touchend", this.pullend.bind(this));
    this.handle.addEventListener("mouseup", this.pullend.bind(this));

    this.$el.addEventListener("touchmove", this.move.bind(this));
    this.$el.addEventListener("mousemove", this.move.bind(this));

    // draw nodes
    for (let i = 0, x = 120 + (Math.random() * 20 - 10), y = 2; i < steps; ++i, y += 400/steps) {
      this.points.push({
        x: x,
        y: y,
        lx: x,
        ly: y + 10 * Math.random(),
        distFromParent: 11
      });
    }

    this.points[0].x      = 100;
    this.points[0].y      = 0;
    this.points[0].fixed  = true;

    this.numPoints = this.points.length;
  }
};
</script>