<template>
  <div class="layer pre-result">

    <div class="content" v-if="this.monument" @click="nextStep">

      <div v-html="contentText"></div>

      <button>{{ $store.getters.copy('button_laatzien') }}</button>
    </div>
    
    <div class="close" @click="close"></div>

  </div>
</template>

<script>
export default {
  name: "PreResult",

  components: {},

  data() {
    return {
      monument: null
    }
  },

  computed: {
    contentText() {
      // console.log(this.monument)
      return this.$store.getters.copy('editor_preresult_text', this.monument);
    }
  },

  methods: {
    open(monument) {
      this.monument = monument;

      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    close(skipEmit) {
      this.$el.classList.remove("show");

      this.monument = null;

      skipEmit == true || this.$emit("closed");
    },

    nextStep() {
      this.$emit("clickedNext");
    }
  },
};
</script>

<style lang="scss">
@import "../scss/pre-result";
</style>