import * as THREE from "three";
import Ornament from "@/universe/ornament";

class OrnamentGroup {

    constructor(scene, universe) {

        let offset = 0;
                
        offset = Math.PI * 0.2;
        let ornament1 = new Ornament(universe, [
            { src: require("@/assets/tante_Riek_muurtje_600x600px.png"), x: 5, y: 0, z: 0, scale: 2 },
            { src: require("@/assets/tante_Riek_riekengeitje600x600px.png"), x: 0, y: -2, z: -4, scale: 2 },
            { src: require("@/assets/tante Riek_wolk_600x600px.png"), x: -10, y: -18, z: -18, scale: 1 },
        ]);
        ornament1.position(Math.sin(offset) * 180, -12, Math.cos(offset) * 180);
        ornament1.rotateY(Math.sin(offset));
        scene.add(ornament1.obj);
        
        offset = Math.PI * 0.5;
        let ornament2 = new Ornament(universe, [
            { src: require("@/assets/vallendeman_wolkjes_600x600px.png"), x: 0, y: 0, z: -4, scale: 2 },
            { src: require("@/assets/vallendeman_plateau_600x600px.png"), x: 0, y: 0, z: 0, scale: 2 },
            { src: require("@/assets/vallendeman_alleenman_600x600px.png"), x: 0, y: 2, z: -4, scale: 2 },
        ]);
        ornament2.position(Math.sin(offset) * 180, -30, Math.cos(offset) * 180);
        ornament2.rotateY(Math.sin(offset));
        scene.add(ornament2.obj);
        
        offset = Math.PI * 1.0;
        let ornament3 = new Ornament(universe, [
            { src: require("@/assets/JopieGerritsen_wolkjes_600x600px.png"), x: 0, y: 0, z: -4, scale: 2 },
            { src: require("@/assets/JopieGerritsen_hekwerk_600x600px.png"), x: 0, y: 0, z: 0, scale: 2 },
            { src: require("@/assets/JopieGerritsen_schommel_600x600px.png"), x: 0, y: 2, z: -4, scale: 2 },
        ]);
        ornament3.position(Math.sin(offset) * 180, -30, Math.cos(offset) * 180);
        ornament3.rotateY(Math.sin(offset));
        scene.add(ornament3.obj);
        
        offset = Math.PI * 1.4;
        let m4rot = 1.1;
        let ornament4 = new Ornament(universe, [
            { src: require("@/assets/indisch_monument_wolkjes_600x600px.png"), x: 0, y: 0, z: -4, scale: 2 },
            { src: require("@/assets/indisch_monument_plateau_600x600px.png"), x: 0, y: 0, z: 0, scale: 2 },
            { src: require("@/assets/indisch_monument_beeld_600x600px.png"), x: 0, y: 2, z: -4, scale: 2 },
        ]);
        ornament4.position(Math.sin(offset) * 150, -30, Math.cos(offset) * 150);
        ornament4.obj.rotation.y = m4rot;
        scene.add(ornament4.obj);
        
        offset = Math.PI * 1.8;
        let m5rot = 2.5;
        let ornament5 = new Ornament(universe, [
            { src: require("@/assets/joodskindermonument_bomen_600x600px.png"), x: 16, y: 0, z: -4, scale: 2 },
            { src: require("@/assets/joodskindermonument_wolkachterin_600x600px.png"), x: -4, y: -2, z: 0, scale: 1 },
            { src: require("@/assets/joodskindermonument_alleenmonument_600x600px.png"), x: 10, y: 4, z: 0, scale: 2 },
            { src: require("@/assets/joodskindermonument_wolkvoorin_600x600px.png"), x: 16, y: -8, z: 9, scale: 1 },
        ]);
        ornament5.position(Math.sin(offset) * 150, -30, Math.cos(offset) * 150);
        ornament5.obj.rotation.y = m5rot;
        scene.add(ornament5.obj);
        
        
        // document.addEventListener('keyup', (e) => {
        //     if (e.code === "ArrowLeft") {
        //         m5rot -= 0.1;
        //     }
        //     else if (e.code === "ArrowRight") {
        //         m5rot += 0.1;
        //     }
        //     ornament5.obj.rotation.y = m5rot;
        //     console.log(m5rot);
        // });
        
    }

}

export default OrnamentGroup;