<template>
  <div :id="`monument-${monument.ID}`" class="monument">
    <div class="dot"></div>
    <div ref="dotIcon" class="dot-icon">
      <div class="dot-icon-info">
        <div class="info-naam">{{ monument.monument_naam }}</div>
        <div class="info-plaats"><markerSVG class="marker"/> {{ monument.monument_plaatsnaam }}</div>
        <div class="info-icoon"><img :src="monument.icoon.url"></div>
      </div>
    </div>
    <div ref="visual" class="visual" :style="styleAttrib" @click="activate">
      <div class="gradient"></div>
      <div class="top-content">
        <div class="thema" v-html="monument.thema"></div>
        <div class="locatie" v-if="state === 'activated'">
          <div class="naam" v-html="monument.monument_naam"></div>
          <markerSVG class="marker"/>
          <div class="plaats" v-html="monument.monument_plaatsnaam"></div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="title" v-html="monumentTitle"></div>
        <playSVG class="play-icon"/>
      </div>
      <div v-if="monument.additionele_informatie" ref="info" class="info">
        <div class="info-text">{{ monument.additionele_informatie }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import markerSVG from "@/assets/marker.svg?inline";
import playSVG from "@/assets/play2.svg?inline";
import scr from '@/util/screen';
import States from '@/util/states';
import Arrow from '@/util/arrow';

export default {
  name: "SelectableMonument",

  components: {
    playSVG,
    markerSVG,
  },

  props: {
    monument: Object,
    index: Number,
  },

  data() {
    return {
      state: States.IDLE,
      visualPositionTransform: '',
      animIframeInt: null,
      arrow: null,
      exposeArrow: null,
    };
  },

  computed: {
    styleAttrib() {
      return `background-image: url(${this.monument.afbeelding.url})`;
    },

    monumentTitle() {
      return this.$store.getters.copy('verhaal_title_pre') + `<br><em>${this.monument.title}</em>`
    }
  },

  methods: {
    pushback() {
      // console.log('pushback');
      clearInterval(this.animIframeInt);
      this.$el.classList.remove('active');
      this.$el.classList.add('back');

      this.$refs.visual.style.transform = this.visualPositionTransform;

      this.state = States.DEACTIVATED;
    },

    expose() {
      const r = Math.random() < 0.5 ? -3 : 3;
      this.$refs.visual.style.transform = `${this.visualPositionTransform} rotate(${r}deg) scale(1.1) `;
      this.$refs.visual.style.transition = 'all 1500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)';

      if (this.$refs.dotIcon) {
        this.$refs.dotIcon.classList.add('active');
      }
      if (this.$refs.visual) {
        this.$refs.visual.classList.add('exposed');
      }
    },

    deexpose() {
      this.$refs.visual.style.transform = this.visualPositionTransform;

      if (this.$refs.dotIcon) {
        this.$refs.dotIcon.classList.remove('active');
      }
      if (this.$refs.visual) {
        this.$refs.visual.classList.remove('exposed');
      }
    },

    deactivate() {
      console.log('deactivate');
      clearInterval(this.animIframeInt);
      this.$el.classList.remove('active', 'back');
      this.$el.classList.add('deactive');

      this.$refs.visual.style.transform = this.visualPositionTransform;

      this.state = States.IDLE;
    },

    activate() {
      this.$el.classList.remove('deactive', 'back');

      if (this.$el.classList.contains('active')) {
        console.log('play');

        this.$emit('openSlices', this);

        this.state = States.SLICES;

        return;
      }
        
      console.log('activate');

      this.$emit('activate', this);

      this.$el.classList.add('active');

      this.state = States.ACTIVATED;

      const offset = {
        x: 0.01 * (-50 + parseInt(this.$el.style.left)),
        y: 0.01 * (-50 + parseInt(this.$el.style.top)),
      }
      const scrSize = scr.size();

      this.$refs.visual.style.transform = `
        translate(calc(-50% - ${offset.x * scrSize.width}px), calc(-50% - ${offset.y * scrSize.height}px)) 
        scale(2.5) 
        rotate(0) 
      `;

      // show iframe after animation
      clearInterval(this.animIframeInt);

      this.animIframeInt = setInterval(() => {
        if (this.$refs.visual && this.$refs.visual.getBoundingClientRect().width === 750) {
          clearInterval(this.animIframeInt);

          // this.$refs.visual.style.visibility = 'hidden';

          // console.log('emitting activated')
          this.$emit('activated', this);
        }
      }, 20);

    },

  },

  mounted() {
    this.$el.style.left = `${this.monument.xpos}%`;
    this.$el.style.top = `${this.monument.ypos}%`;

    this.visualPositionTransform = ` translate(${this.monument.vxpos}px, ${this.monument.vypos}px) `;
    
    this.$refs.visual.style.transform = this.visualPositionTransform;
    
    if (!this.arrow) {
      this.arrow = new Arrow(240, 100);
      this.$refs.info.appendChild(this.arrow);
    }
    
    if (!this.exposeArrow) {
      this.exposeArrow = new Arrow(this.monument.aw, this.monument.ah);
      this.exposeArrow.style.transform = `translate(${this.monument.ax}px, ${this.monument.ay}px) rotate(${this.monument.ar}deg)`;
      this.exposeArrow.classList.add('expose-arrow');
      this.$refs.dotIcon.appendChild(this.exposeArrow);
    }
  },
};
</script>