const md5 = require('md5');

export default {

    prepare: (n, e, m) => {
        console.log(n, e, m);
        // 'h' . md5('e' . $from_email . '--' . $from_name);
        const h = 'h' + md5(`e${e}--${n}`);
        
        return { n, e, m, h }
    }

}