<template>
  <div :class="`layer ritueel-subplayer ritueel-${typeClass}`">

    <!-- title-->
    <div v-if="isPlayer && ritueel" class="player-title-container">
      <div class="player-title">{{ ritueel.term_name }}</div>
    </div>

    <!-- backdrop-->
    <div v-if="needsBackdrop" class="backdrop"></div>
    
    <!-- afbeelding backdrop -->
    <div v-if="ritueel && ritueel.heeft_afbeelding && ritueel.type !== 'gedicht'" class="backdrop-image" :style="`background-image: url(${ritueel.afbeelding.url})`"></div>

    <!-- content --> 
    <div v-if="ritueel" class="screen">
    
      <!-- afbeelding -->
      <img v-if="ritueel.heeft_afbeelding && ritueel.type == 'gedicht'" class="image" :src="`${ritueel.afbeelding.url}`">
      
      <!-- gedicht -->
      <div v-if="ritueel.type === 'gedicht'" class="text">
        <paperSVG class="paper"/>
        <span v-html="ritueel.gedicht"></span>
      </div>
      <div v-if="ritueel.type === 'gedicht'" class="gedicht-title">
        <div class="title">{{ ritueel.title }}</div>
        <div class="subtitle">{{ ritueel.gedicht_subtitel }}</div>
      </div>

      <!-- video -->
      <div v-if="ritueel.heeft_video" class="videoContainer">
        <div class="plyr__video-embed" id="videoPlayer" v-html="ritueel.video"></div>
      </div>
      
      <!-- audio -->
      <div v-if="ritueel.heeft_geluidsfragment" class="audioContainer">
        <audio id="audioPlayer" controls preload="metadata"></audio>
      </div>
      <!-- <div v-if="isPlayer && ritueel.heeft_geluidsfragment" class="vu-meter">
        <div v-for="i in 4" :key=i class="bar"></div>
      </div> -->
    
      <!-- no content -->
      <div v-if="!hasContent || alleenBijschrift" class="no-content">
        <div class="image-container" :style="`background-image: url(${ritueel.rdisplay_image})`"></div>
      </div>
      
      <!-- bijschrift -->
      <div class="caption" v-if="ritueel.heeft_tekst" v-html="ritueel.tekst"></div>

    </div>
    
    <div v-if="!isPlayer" class="closeButContainer">
      <div class="close" @click="close"></div>
    </div>

  </div>

</template>

<script>
import Plyr from 'plyr';

import paperSVG from "@/assets/paper.svg?inline";

export default {
  name: "RitueelSubPlayer",

  components: {
    paperSVG,
  },

  props: {
    isPlayer: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      supports: "",
      ritueel: null,
      player: null,
      playerEndCallback: null,
    }
  },

  computed: {
    typeClass() {
      return this.ritueel ? this.ritueel.type : '';
    },

    needsBackdrop() {
      return this.ritueel && !this.ritueel.heeft_video;
    },

    hasContent() {
      return this.ritueel && (this.ritueel.heeft_video 
        || this.ritueel.heeft_geluidsfragment
        || this.ritueel.heeft_tekst
        || this.ritueel.heeft_afbeelding);
    },

    alleenBijschrift() {
      // console.log(this.ritueel.heeft_video 
      //   , this.ritueel.heeft_geluidsfragment
      //   , this.ritueel.heeft_afbeelding
      //   , this.ritueel.heeft_tekst)
      return this.ritueel && (!this.ritueel.heeft_video 
        && !this.ritueel.heeft_geluidsfragment
        && !this.ritueel.heeft_afbeelding
        && this.ritueel.heeft_tekst);
    },
  },

  methods: {
    open(ritueel) {
      this.ritueel = ritueel;
      // console.log(ritueel)

      /**
       * ---------------------
       *  GELUID 
       * ---------------------
       */
      if (ritueel.heeft_geluidsfragment) {
        setTimeout(() => {

          const player_el = document.getElementById('audioPlayer');
          this.player = new Plyr(player_el, {
            controls: ['play', 'progress', 'mute', 'current-time'],
            hideControls: false
          });

          if (!player_el) {
            // console.log('no player');
            return;
          }
          
          this.supports = "Supports:";

          if (player_el.canPlayType('audio/mpeg')) {
            // console.log('mp3 supported');
            this.supports += " mp3";
            player_el.setAttribute('src', ritueel.geluidsfragment);
          }
  
          if (player_el.canPlayType('audio/mp4')) {
            this.supports += " mp4";
            // console.log('mp4 supported');
          }
  
          if (player_el.canPlayType('audio/ogg')) {
            this.supports += " ogg";
            // console.log('ogg supported');
          }

          // end listener
          this.player.on('ended', this.playerEnded);

          // start playing when ready
          this.player.on('ready', event => {
            const instance = event.detail.plyr;
            // console.log('on plyr ready');
            instance.play();
          });

          // error listener
          this.player.on('error', this.playerError);

        }, 200)
      }

      /**
       * ---------------------
       *  VIDEO 
       * ---------------------
       */
      if (ritueel.heeft_video) {
        setTimeout(() => {

          const player_el = document.getElementById('videoPlayer');
          this.player = new Plyr(player_el, {
            controls: this.isPlayer ? [] : ['play', 'progress', 'mute', 'current-time'],
            hideControls: false,
            autoplay: true, 
            fullscreen: {
              enabled: true,
              fallback: true,
              iosNative: true,
            },
            quality: {
              default: 720
            },
            vimeo: {
              byline: false,
              dnt: true,
              autoplay: true,
            },
            youtube: {
              noCookie: true,
              showinfo: 0,
              autoplay: 1,
              cc_load_policy: 0, 
              iv_load_policy: 3, 
              modestbranding: 1, 
              disablekb: 1, 
              rel: 0, 
              showinfo: 0,
            }
          });

          player_el.style.visibility = 'hidden';

          if (!player_el) {
            // console.log('no player');
            return;
          }

          // start playing when ready
          this.player.on('ready', this.videoPlayerReady);

          // end listener
          this.player.on('ended', this.playerEnded);

          // listen for endtime
          if ("undefined" !== typeof this.ritueel.video_end && this.ritueel.video_end > 0) {
            console.log(`video will stop at ${this.ritueel.video_end}s`)
            this.player.on('timeupdate', this.videoTimeUpdate);
          }

          // error listener
          this.player.on('error', this.playerError.bind(this), true);

        }, 200)
      }

      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    playerError(event) {
      const player = document.getElementById('audioPlayer') || document.getElementById('videoPlayer') || null;
      if (player) {
        console.warn('playerError', player.error.message);
      } else {
        console.warn('playerError', 'no info found');
      }
      this.playerEnded(event, true);
    },

    playerEnded(event, dueError) {
      console.log('playerEnded');
      if ("undefined" === typeof dueError) {
        dueError = false;
      }
      if (this.playerEndCallback) {
        this.playerEndCallback.call(this, {dueError});
      }
    },

    videoPlayerReady(event) {
      const player_el = document.getElementById('videoPlayer');
      const instance = event.detail.plyr;
      
      console.log('on plyr ready');
      instance.play();

      // respect start and endtimes
      if ("undefined" !== typeof this.ritueel.video_start && this.ritueel.video_start > 0) {

        if (this.ritueel.is_vimeo) {
          instance.embed.setCurrentTime(this.ritueel.video_start);
        } else if (this.ritueel.is_youtube) {
          instance.forward(this.ritueel.video_start);
        }
        console.log(`video forwarded to ${this.ritueel.video_start}s`)
      }

      player_el.style.visibility = 'visible';
    },

    videoTimeUpdate(event) {
      const instance = event.detail.plyr;
      if (instance.currentTime > this.ritueel.video_end) {
        console.log(`video stopped at ${instance.currentTime} (${this.ritueel.video_end})s`)
        instance.pause();
        this.playerEnded();
      }
    },

    close() {
      console.log('trying to close ritueelsubplayer')
      // console.log('--------1')
      this.$el.classList.remove("show");

      // console.log('--------2')
      if (this.player) {
        // console.log('--------2a')
        this.player.off('ready', this.videoPlayerReady);
        this.player.off('timeupdate', this.videoTimeUpdate);
        this.player.destroy();
      }

      // console.log('--------3')
      this.ritueel = null;
      this.player = null;
      this.playerEndCallback = null;

      // console.log('--------4')
      this.$emit("closed");
    },

    onPlayerEnd(callback) {
      this.playerEndCallback = callback;
    }

  },
};
</script>

<style lang="scss">
@import "../scss/ritueel-subplayer";
@import "../../node_modules/plyr/dist/plyr.css";
</style>