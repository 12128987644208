<template>
  <div class="layer order-screen">

    <div class="content" @click="nextStep">

      <div v-html="$store.getters.copy('editor_order_text')"></div>

      <ul class="rituelen" id="rituelen">
        <li v-for="(ritueel, index) in rituelen" :key="index" :data-type="ritueel.type" :class="ritueelClass(ritueel.type)">
          <span class="label">{{ ritueel.titel }}</span>
          <span class="icon">
            <gedichtSVG v-if="ritueel.type === `gedicht`" />
            <muziekSVG v-if="ritueel.type === `muziek`" />
            <stilteSVG v-if="ritueel.type === `stilte`" />
            <wilhelmusSVG v-if="ritueel.type === `wilhelmus`" />
            <bloemenSVG v-if="ritueel.type === `neerleggen`" />
          </span>
          <span class="spacer"></span>
        </li>
      </ul>
      <ul class="order">
        <li v-for="(ritueel, index) in rituelen" :key="index" :class="ritueelClass(ritueel.type)">
          <span></span>
        </li>
      </ul>

      <button>{{ $store.getters.copy('button_opslaanafspelen') }}</button>
    </div>
    
    <div class="close" @click="close"></div>

  </div>
</template>

<script>
import Sortable from 'sortablejs';

import gedichtSVG from "@/assets/gedicht.svg?inline";
import muziekSVG from "@/assets/muziek.svg?inline";
import stilteSVG from "@/assets/stilte.svg?inline";
import wilhelmusSVG from "@/assets/wilhelmus.svg?inline";
import bloemenSVG from "@/assets/bloemen.svg?inline";

export default {
  name: "OrderScreen",

  components: {
    gedichtSVG,
    muziekSVG,
    stilteSVG,
    wilhelmusSVG,
    bloemenSVG,
  },

  props: {
  	chosen: { type: Object },
  },

  data() {
    // create rituelen list with copy from store
    const rituelen = [];
    for (const [type, ritueel] of Object.entries(this.$store.getters.rituelen())) {
      rituelen.push({ type, titel: ritueel.titel });
    }

    return {
      rituelen
    };
  },

  computed: {
    chosenAmount() {
      return 5;
    }
  },

  methods: {
    open(monument) {
      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    close() {
      this.$el.classList.remove("show");

      this.$emit("closed");
    },

    nextStep() {
      const rituelen = this.$el.querySelectorAll('ul.rituelen li');
      const rituelenList = [];
      rituelen.forEach(el => {
        rituelenList.push(el.dataset.type);
      });
      // console.log(rituelenList)
      this.$emit("clickedNext", rituelenList);
    },

    ritueelClass(ritueelType) {
      console.log(ritueelType, this.chosen, this.chosen[ritueelType])
      return this.chosen[ritueelType] == -1 ? 'disabled' : '';
    },
  },

  mounted() {
    const rituelen = document.getElementById('rituelen');
    const sortable = Sortable.create(rituelen, {
      direction: 'horizontal',
      chosenClass: "chosen",
    	dragClass: "drag"
    });
  }
};
</script>

<style lang="scss">
@import "../scss/order-screen";
</style>