import * as THREE from "three";

// import * as dat from 'dat.gui';

const Z_SPACING = 3;
const TEXTURE_MESH_RATIO = 0.05;

class Ornament {

    elements = [];

    universe = null;

    camera = null;

    offset = null;

    ratio = null;

    constructor(universe, elements, ratio) {

        this.universe = universe;

        this.camera = universe.pcam.camera;

        if (elements) {
            this.elements = elements;
        }

        this.ratio = ratio || TEXTURE_MESH_RATIO;

        this.group = new THREE.Group();

        // create layers
        this.elements.forEach((el, i) => {
            const loader = new THREE.TextureLoader();
            loader.load(el.src,
                (texture) => {
                    texture.format = THREE.RGBAFormat;
                    texture.magFilter = THREE.LinearFilter;

                    // geom
                    const geometry = new THREE.BoxGeometry(texture.image.width * this.ratio * el.scale, texture.image.height * this.ratio * el.scale, 0);

                    // mat
                    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true, opacity: 1 });
                    material.alphaTest = 0.1;
                    material.depthTest = false;
                    // material.depthWrite = true;

                    // mesh
                    const mesh = new THREE.Mesh(geometry, material);
                    mesh.position.x = -el.x;
                    mesh.position.y = -texture.image.height * this.ratio;
                    mesh.position.z = i * -Z_SPACING + el.z;

                    // pivot point
                    const pivot = new THREE.Object3D();
                    pivot.position.y = texture.image.height * this.ratio + el.y;
                    pivot.add(mesh);

                    // group 
                    this.group.add(pivot);

                    material.needsUpdate = true;

                    // store reference
                    this.elements[i].mesh = mesh;
                }
            );
        });
    }

    get obj() {
        return this.group;
    }

    position(x, y, z) {
        this.group.position.x = x;
        this.group.position.y = y;
        this.group.position.z = z;
    }

    rotateY(rot) {
        this.group.rotateY(rot);
    }
}

export default Ornament;