<template>
  <div class="player-instance">
    <div class="feedback">
      <div v-if="isLoading">Inladen herdenking {{ $route.params.doecode }}...</div>
      <div v-if="is404">Herdenking {{ $route.params.doecode }} niet gevonden</div>
    </div>

    <PlayerIntro v-if="hasValidResult" ref="playerintro" :monument="herdenking.verhaal" @clicked="startRituelen"/>

    <PlayerEnd v-if="hasValidResult" ref="playerend" :monumentID="herdenking.verhaal.ID"/>

    <RitueelSubPlayer ref="ritueelsubplayer" :isPlayer="true" />

    <MonumentTitle v-if="hasValidResult" :monument="herdenking.verhaal"/>

    <ProgressBar v-if="hasValidResult" ref="progressbar" :rituelen="herdenking.rituelen" @clickedSkip="skipHandler"/>

    <div ref="skip" class="skip" @click="tryNextRitueel">
      <skipSVG />
      <span class="label">skip</span>
    </div>
    
  </div>
</template>

<script>
import { isMobile, isIOS, isAndroid } from 'mobile-device-detect';

import Settings from '@/config/settings';

import PlayerIntro from '@/components/PlayerIntro';
import PlayerEnd from '@/components/PlayerEnd';
import RitueelSubPlayer from '@/components/RitueelSubPlayer';
import MonumentTitle from '@/components/MonumentTitle';
import ProgressBar from '@/components/ProgressBar';

import skipSVG from '@/assets/skip.svg?inline';

const axios = require("axios");

export default {
  name: "Player",

  data() {
    return {
      herdenking: '',
      isLoading: true,
      is404: false,
      hasValidResult: false,
      currentRitueel: null,
      currentRitueelIndex: -1,
      timer: null,
    };
  },

  components: {
    PlayerIntro,
    PlayerEnd,
    MonumentTitle,
    RitueelSubPlayer,
    ProgressBar,
    skipSVG,
  },

  methods: {
    skipHandler() {
      console.log('skipHandler')
    },

    start() {
      console.log('start')

      console.log(this.herdenking, this.herdenking.rituelen.length)

      this.$refs.playerintro.show();

      this.$refs.progressbar.show();
      
      // setTimeout(() => this.nextRitueel(), 3000);
    },

    end() {
      console.log('end');

      this.$refs.progressbar.setBar(this.herdenking.rituelen.length + 1);

      this.$refs.playerend.show();
    },

    startRituelen() {
      this.$refs.playerintro.hide();
      this.tryNextRitueel();
    },

    tryNextRitueel() {
      console.log('tryNextRitueel')
      clearTimeout(this.timer);
      !this.$refs.ritueelsubplayer || this.$refs.ritueelsubplayer.close();

      // console.log('--2')
      this.$refs.skip.classList.remove('show');

      // console.log('--3')
      if (this.currentRitueelIndex < this.herdenking.rituelen.length - 1) {
        // console.log('--3a')
        this.timer = setTimeout(() => this.nextRitueel(), 1000);
      } else {
        // console.log('--3b')
        this.timer = setTimeout(() => this.end(), 1000);
      }
    },

    nextRitueel() {
      // console.log('--4')
      this.currentRitueelIndex++;
      // console.log('--5')
      console.log(`nextRitueel ${this.currentRitueelIndex}/${this.herdenking.rituelen.length}`)
      
      // console.log('--6')
      this.currentRitueel = this.herdenking.rituelen[this.currentRitueelIndex];
            
      // console.log('--7')
      this.$refs.progressbar.setBar(this.currentRitueelIndex + 1);

      // console.log('--8')
      this.timer = setTimeout(() => {
        // console.log('--8a')
        this.showRitueel();

      }, 1000)
    },

    showRitueel() {
      console.log(`Playing ritueel: ${this.currentRitueel.title}`);

      // console.log('--9')
      this.$refs.ritueelsubplayer.open(this.herdenking.rituelen[this.currentRitueelIndex]);

      // console.log('--10')
      this.$refs.skip.classList.add('show');

      // console.log('--11')
      /**
       * Wait for player end
       */
      if (this.currentRitueel.heeft_video || this.currentRitueel.heeft_geluidsfragment) {
        this.$refs.ritueelsubplayer.onPlayerEnd((info) => {
          console.log('onPlayerEnd', info);

          this.timer = setTimeout(() => {
            this.tryNextRitueel();
          }, info.dueError === true ? Settings.PLAYER_RITUEEL_TIMEOUT : 100);
        })

      /**
       * Wait for timeout 
       */  
      } else {
        this.timer = setTimeout(() => this.tryNextRitueel(), Settings.PLAYER_RITUEEL_TIMEOUT);
      }
    },
  },

  mounted() {
    // console.log(isMobile, isIOS, isAndroid);
   

   
    axios
      .get(
        (window.API_URL || process.env.VUE_APP_API_URL) + "herdenken/" + this.$route.params.doecode
      )

      .then((response) => {
        // console.log(response.data);
        this.isLoading = false;

        if (response.data === false) {

          this.is404 = true;

        } else {

          this.herdenking = response.data;
          this.hasValidResult = true;

          // skip for devs
          if (Settings.DEV_SKIP_PLAYER && process.env.NODE_ENV === 'development') {
            this.currentRitueelIndex = this.herdenking.rituelen.length - 1;
          }

          setTimeout(() => this.start(), 250);
        }
      })

      .catch((error) => {
        console.warn(error);
      });
  },
};
</script>

<style lang="scss">
@import "../scss/player";
</style>