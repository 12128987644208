<template>
  <div ref="intro" class="editor-intro">
    <div class="content" v-html="$store.getters.copy('editor_intro_text', monument)"></div>
    <button @click="hide()">{{ $store.getters.copy('button_aandeslag') }}</button>
    <div class="omschrijving">{{ $store.getters.copy('editor_intro_explain_text') }}</div>
  </div>
</template>

<script>
import Arrow from '@/util/arrow';

export default {
  name: "EditorIntro",

  props: {
    monument: Object
  },

  data() {
    return {
      arrow: null,
    }
  },

  methods: {
    hide() {
      this.$el.classList.add('hide');
      this.$emit('closed');
    }
  },

  mounted() {
    if (!this.arrow) {
      this.arrow = new Arrow(300, 150);
      this.$refs.intro.appendChild(this.arrow);
    }
  }
};
</script>