export default {

    // use sounds
    USE_AUDIO                   : false,

    // skips
    DEV_SKIP_INTRO              : true,
    DEV_SKIP_EDITOR             : false,
    DEV_SKIP_PLAYER             : false,

    // timeout (ms) for next dot in universe
    DOTS_TIMER_INITIAL          : 150,   
    DOTS_TIMER_TARGET           : 16,   

    // timeout (ms) for next dot in universe
    MAX_DOTS                    : 1200,   

    // how many dots drawn at once per iteration
    DOTS_PER_ITERATION          : 2,   

    // timeout (ms) for next static monument in universe
    STATIC_MONUMENTS_TIMER      : 50,   

    // timeout (ms) for ritueel in player
    PLAYER_RITUEEL_TIMEOUT      : 10000,   

    // positions for monuments (px on 2048 x 1843 backdrop) and visual offsets (px)
    MONUMENT_POSITIONS          : [
        {x:614,     y:1010, vx:-295,    vy:-253,    aw:150, ah:70, ax: 107,  ay:10,   ar: -101}, // 3
        {x:1305,    y:882,  vx:-282,    vy:-311,    aw:100,   ah:40, ax: 100,  ay:6,   ar: -96}, // 1
        {x:676,     y:1100, vx:-150,    vy:36,      aw:180,   ah:80, ax: -14,  ay:44,   ar: 120}, // 4
        {x:1612,    y:1039, vx:-98,     vy:-230,    aw:110,   ah:40, ax: 122,  ay:90,   ar: -30}, // 0 
        {x:1297,    y:1120, vx:43,      vy:17,      aw:120,   ah:80, ax: 10,  ay:111,   ar: 50}, // 2
    ],
    
    // positions for static monuments (px on 2048 x 1843 backdrop) 
    STATIC_MONUMENT_POSITIONS   : [
        {x:1083, y:449},
        {x:1205, y:258},
        {x:1512, y:159},
        {x:1667, y:169},
        {x:1538, y:466},
        {x:1657, y:539},
        {x:1558, y:661},
        {x:1188, y:610},
        {x:1386, y:681},
        {x:1287, y:766},
        {x:1443, y:887},
        {x:1208, y:859},
        {x:1076, y:932},
        {x:1268, y:953},
        {x:1366, y:990},
        {x:1406, y:1091},
        {x:1146, y:1049},
        {x:1228, y:1208},
        {x:1106, y:1371},
        {x:1258, y:1454},
        {x:1277, y:1621},
        {x:1248, y:1760},
        {x:874, y:1304},
        {x:766, y:1198},
        {x:435, y:1449},
        {x:358, y:1320},
        {x:746, y:1053},
        {x:656, y:963},
        {x:972, y:902},
        {x:1056, y:786},
        {x:884, y:701},
        {x:834, y:601},
        {x:834, y:479},
        {x:854, y:294},
        {x:836, y:766},
        {x:851, y:786},
        {x:836, y:805},
        {x:820, y:786},

        {x:1612, y:1039}, 
        {x:1305, y:882}, 
        {x:1297, y:1120}, 
        {x:614, y:1010}, 
        {x:676, y:1140}, 
    ],

    // how many rituelen allowed to skip 
    ALLOWED_SKIP_RITUEEL_AMOUNT : 2,

}