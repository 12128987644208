<template>
  <div class="notifications">
    <div class="notification">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationBar",

  components: {
  },

  data() {
    return {
      message: ''
    }
  },

  methods: {
    show(_message) {
      this.message = _message;
      this.$el.classList.add('show');
      setTimeout(() => this.$el.classList.remove('show'), 2500);
    }
  },
};
</script>

<style lang="scss">
@import "../scss/notifications";
</style>