<template>
  <div class="c-logo">
      <img :src="require('@/assets/zuurstok-header.png')" class="zuurstok">
      <backplateSVG class="backplate"/>
      <div class="menu-title">{{ $store.getters.copy('app_title') }}</div>
      <logoSVG class="logo"/>
  </div>
</template>

<script>
import logoSVG from "@/assets/adopteereenmonument.svg?inline";
import backplateSVG from "@/assets/logobackplate.svg?inline";

export default {
  name: "Logo",

  components: {
    logoSVG,
    backplateSVG,
  },
};
</script>