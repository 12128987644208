// import {Howl, Howler} from 'howler';
import {Howl} from 'howler';
import Settings from '@/config/settings';

class Sound {

    asset = null;

    constructor(file) {
        if (Settings.USE_AUDIO === true) {
            this.asset = new Howl({ src: [require(`@/assets/sounds/${file}`)] });
        }
    }

    play() {
        if (Settings.USE_AUDIO === true) {
            this.asset.play();
        }
    }
}

export default Sound;