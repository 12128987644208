<template>
  <div class="c-editor">
    <Universe ref="universe" :monument="monument" @clickedRDisplay="rdisplayClickHandler"/>
    <EditorIntro ref="intro" :monument="monument" @closed="introCloseHandler"/>
    <MonumentTitle :monument="monument"/>
    <EditBar ref="editbar" @clickedRDisplay="rdisplayClickHandler" @clickedNextStep="nextStepHandler"/>
    <RitueelSubSelector ref="ritueelsubselector" :monument="monument" @selectRitueel="selectRitueelHandler" @skipRitueel="skipRitueelHandler" @playRitueel="playRitueelHandler" @screenClick="screenClickHandler" @closed="closedRitueelSubSelectHandler"/>
    <RitueelSubPlayer ref="ritueelsubplayer" @closed="closedRitueelSubPlayerHandler"/>
    <PreResult ref="preresult" @clickedNext="preresultClickHandler" @closed="closedDefaultHandler"/>
    <OrderScreen ref="orderscreen" :chosen="chosen" @clickedNext="orderscreenClickHandler" @closed="closedDefaultHandler"/>
    <NotificationBar ref="notifications" />
    <PostOverlay ref="postoverlay"/>
  </div>
</template>

<script>
const axios = require('axios');

import Universe from '@/components/Universe';
import EditBar from '@/components/EditBar';
import MonumentTitle from '@/components/MonumentTitle';
import RitueelSubSelector from '@/components/RitueelSubSelector';
import RitueelSubPlayer from '@/components/RitueelSubPlayer';
import EditorIntro from '@/components/EditorIntro';
import PreResult from '@/components/PreResult';
import OrderScreen from '@/components/OrderScreen';
import PostOverlay from '@/components/PostOverlay';
import NotificationBar from '@/components/NotificationBar';

import States from '@/util/states';
import Sound from '@/util/sound';
import Post from '@/util/post';
import Settings from '../config/settings';

const sndClick      = new Sound("beep-timberl.mp3");
const sndSelect     = new Sound("beep-timberl-sel.mp3");
const sndClickOut   = new Sound("beep-timberl2.mp3");
const sndButton     = new Sound("beep-rejected.mp3");

export default {
  name: "Editor",

  data() {
    const chosen = {};
    for (const [type, ritueel] of Object.entries(this.$store.getters.rituelen())) {
      chosen[type] = null;
    }

    return {
      skipClosedSound: false,
      state: States.IDLE,
      chosen: {
        gedicht: null,
        muziek: null,
        stilte: null,
        wilhelmus: null,
        neerleggen: null,
      },
      doecode: null,
      monument: this.$store.getters.getMonumentById(this.$route.params.id)
    };
  },

  components: {
    Universe,
    EditBar,
    MonumentTitle,
    RitueelSubSelector,
    RitueelSubPlayer,
    EditorIntro,
    PreResult,
    OrderScreen,
    PostOverlay,
    NotificationBar,
  },

  methods: {
    /**
     * Called when intro is closed
     */
    introCloseHandler() {
      sndSelect.play();
      this.$refs.universe.resume();
      this.$refs.universe.activate();
    },

    /**
     * Called when button in editbar, or rdisplay is clicked
     */
    rdisplayClickHandler(data) {
      console.log('rdisplayClickHandler');
      sndClick.play();

      // pan to selected rdisplay
      const rdisplay = this.$refs.universe.rDisplaysList.find(rdisplay => rdisplay.type === data.type); 
      !rdisplay || this.$refs.universe.pcam.lookAt(rdisplay);

      this.$refs.universe.pause();
      this.$refs.universe.setBlur(true);
      this.$refs.editbar.setBlur(true);
      
      // open subselector
      this.$refs.universe.deactivate();
      this.$refs.ritueelsubselector.open(data.type);
    },

    /**
     * Called when ritueel subselector screens 
     * are clicked
     */
    screenClickHandler() {
      sndClick.play();
    },

    /**
     * Called when one of ritueel subselector rituelen 
     * select button is clicked
     */
    selectRitueelHandler(ritueel) {
      sndSelect.play();

      console.log('chosen ritueel', ritueel)
      // update rdisplay 
      this.$refs.universe.updateRDisplayForType(ritueel.term_slug, ritueel.ID);
      // update editbar 
      this.$refs.editbar.addSelectedRitueelType(ritueel.term_slug);
      // add to list of selected
      // if (!this.chosen[ritueel.term_slug]) {
      this.chosen[ritueel.term_slug] = ritueel.ID;
      // } 
      // close ritueelsubselector 
      this.skipClosedSound = true;
      this.$refs.ritueelsubselector.close();

      console.log(this.chosen);
    },

    /**
     * Called when one of ritueel subselector rituelen 
     * skip button is clicked
     */
    skipRitueelHandler(ritueelType) {
      sndSelect.play();

      const skippedAmount = this.getSkippedAmount();

      console.log('skipped ' + ritueelType, skippedAmount)

      // check if still allowed to skip
      if (skippedAmount < Settings.ALLOWED_SKIP_RITUEEL_AMOUNT) {

        // update rdisplay 
        this.$refs.universe.updateRDisplayForType(ritueelType, -1);
        // update editbar 
        this.$refs.editbar.addSkippedRitueelType(ritueelType);
        // add to list of selected
        this.chosen[ritueelType] = -1;

      } else {

        this.$refs.notifications.show(this.$store.getters.copy('max_skip_rituelen_melding'));

      }

      // close ritueelsubselector 
      this.skipClosedSound = true;
      this.$refs.ritueelsubselector.close();

      // console.log(this.chosen);
    },

    /**
     * Called when one of ritueel subselector rituelen 
     * play button is clicked
     */
    playRitueelHandler(ritueel) {
      sndButton.play();
      console.log('play ritueel', ritueel);
      this.$refs.ritueelsubplayer.open(ritueel);
    },

    /**
     * Called when ritueel subselector is closed
     */
    closedRitueelSubSelectHandler() {
      if (!this.skipClosedSound || this.skipClosedSound === false) {
        sndClickOut.play();
      }
      this.skipClosedSound = false;

      this.$refs.universe.setBlur(false);
      this.$refs.editbar.setBlur(false);
      this.$refs.universe.resume();
      this.$refs.universe.activate();
    },

    // /**
    //  * Called when ritueel subplayer is closed
    //  */
    // closedRitueelSubPlayerHandler() {
    //   sndClickOut.play();
    // },

    /**
     * 
     */
    nextStepHandler() {
      console.log('nextStepHandler');

      if (!this.$refs.editbar.allSelected()) {
        return;
      }
      
      sndSelect.play();

      this.$refs.universe.pause();
      this.$refs.universe.deactivate();
      this.$refs.universe.setBlur(true, 10);
      this.$refs.editbar.setBlur(true);

      this.$refs.preresult.open(this.monument);
    },

    /**
     * Next step from preresult 
     */
    preresultClickHandler() {
      console.log('preresultClickHandler');

      sndSelect.play();

      this.$refs.preresult.close(true);
      this.$refs.orderscreen.open(this.monument);
    },

    /**
     * Next step from orderscreen 
     */
    orderscreenClickHandler(rituelenList) {
      console.log('orderscreenClickHandler', this.chosen, rituelenList);

      sndSelect.play();

      let postData = {
        rituelen: []
      };
      rituelenList.forEach(ritueelType => {
        if ("undefined" !== typeof this.chosen[ritueelType]) {
          postData.rituelen.push(this.chosen[ritueelType]);
        } else {
          console.warn(`Missing ritueel type ${ritueelType} in chosen`)
          return;
        }
      });

      if (postData.rituelen.length && Object.keys(this.$store.getters.rituelen()).length) {
        console.log(postData.rituelen.join(','));
        // post
        axios({
          method: 'post',
          url: (window.API_URL || process.env.VUE_APP_API_URL) + 'herdenken',
          data: Post.prepare(this.monument.ID.toString(), postData.rituelen.join(','))
        })
          .then((response) => {
            console.log("axios result", response.data);
            
            if (response.data.success) {
              setTimeout(() => {
                this.doecode = response.data.doecode;
                this.$refs.postoverlay.setText(this.$store.getters.copy('feedback_opgeslagen'), false);
              }, 1500);

              setTimeout(() => {
                this.$router.push(`/player/${this.doecode}`)
              }, 3000);
            }
            /** @todo error case maken */

          });
          /** @todo error case maken */

        this.$refs.postoverlay.open(this.$store.getters.copy('feedback_opslaan'));
      }
    },

    closedRitueelSubPlayerHandler() {
      sndClickOut.play();

      // this.$refs.editbar.setBlur(false);
      // this.$refs.universe.setBlur(false);
      // this.$refs.universe.resume();
      // this.$refs.universe.activate();
    },

    closedDefaultHandler() {
      sndClickOut.play();

      this.$refs.editbar.setBlur(false);
      this.$refs.universe.setBlur(false);
      this.$refs.universe.resume();
      this.$refs.universe.activate();
    },

    getSkippedAmount() {
      let skippedAmount = 0;
      for (let p in this.chosen) {
        if (this.chosen.hasOwnProperty(p) && this.chosen[p] === -1) {
            skippedAmount++;
        }
      }
      return skippedAmount;
    },
  },

  mounted() {
    // initial paused
    // this.$refs.universe.pause();
    // this.$refs.universe.deactivate();
    // setTimeout(() => this.$refs.universe.updateUniverse(), 200);
    
    if (process.env.NODE_ENV === 'development' && Settings.DEV_SKIP_EDITOR) {
      this.chosen = {
        neerleggen: 174,
        gedicht: -1,
        wilhelmus: -1,
        muziek: 162,
        stilte: 171,
      }
      this.$refs.intro.hide();
      this.$refs.universe.pause();
      this.$refs.universe.deactivate();
      this.$refs.universe.setBlur(true, 10);
      this.$refs.editbar.setBlur(true);

      // this.$refs.preresult.open(this.monument);

      this.$refs.orderscreen.open(this.monument);
      
      // this.$refs.postoverlay.open();
    }

    setTimeout(() => this.$refs.editbar.show(), 500);
  }
};
</script>

<style lang="scss">
@import "../scss/editor";
</style>
