<template>
  <div class="layer post-overlay">

    <div class="content">
      <span class="text" v-html="textContent"></span>
      <loaderSVG v-if="showLoader"/>
    </div>

  </div>
</template>

<script>
import loaderSVG from "@/assets/loader.svg?inline";

export default {
  name: "PostOverlay",

  components: {
    loaderSVG,
  },

  data() {
    return {
      textContent: '',
      showLoader: true,
    }
  },

  methods: {
    setText(textContent, showLoader) {
      this.textContent = textContent;
      this.showLoader = !(showLoader === false);
    },

    open(textContent) {
      this.setText(textContent);
      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    close() {
      this.$el.classList.remove("show");

      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss">
@import "../scss/post-overlay";
</style>