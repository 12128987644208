<template>
  <div id="container" class="universe"></div>
</template>

<script>
import * as THREE from "three";
import { Interaction } from "three.interaction";

import PCam from "@/universe/pcam";
import ImageLayer from "@/universe/imagelayer";
import RDisplay from "@/universe/rdisplay";
import OrnamentGroup from "@/universe/ornamentgroup";

export default {
  name: "Universe",

  props: {
    monument: Object,
  },

  data() {
    return {
      camera: null,
      controls: null,
      scene: null,
      renderer: null,
      mesh: null,
      rDisplaysList: [],
      monumentCastList: [],
      doUpdate: true,
    };
  },

  methods: {
    init: function () {
      let container = document.getElementById("container");

      if (this.renderer) {
        this.renderer = null;
      }

      // renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: false });
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setClearColor(0x030015, 1);

      // scene
      this.scene = new THREE.Scene();

      // orbital perspective cam
      this.pcam = new PCam(this.renderer);

      // interaction
      const interaction = new Interaction(
        this.renderer,
        this.scene,
        this.pcam.camera
      );

      document.addEventListener('mousemove', this.onMouseMove.bind(this), false );

      this.scene.add(
        new ImageLayer(8000, 8600, require('@/assets/magentakleur5.png'), 12, 1, 0, 0, false)
      );
      this.scene.add(
        new ImageLayer(7000, 8000, require('@/assets/sterrenklein2.png'), 6, 4, 0, -2.7, true)
      );
      this.scene.add(
        new ImageLayer(3000, 1800, require('@/assets/wolkenklein2.png'), 12, 4, 0, -2.8, true)
      );
      this.scene.add(
        new ImageLayer(700, 820, require('@/assets/sterrengroot3.png'), 6, 4, 0, -2.7, true)
      );
      this.scene.add(
        new ImageLayer(490, 580, require('@/assets/bergen_transparant_achtergrond_600.png'), 8, 3, 0, -1.2, true)
      );
      this.scene.add(
        new ImageLayer(390, 460, require('@/assets/skyline_2AB_1200.png'), 6, 2, 0, -0.4, true)
      );
      this.scene.add(
        new ImageLayer(200, 240, require('@/assets/wolkvoorgrond_600x600px.png'), 16, 2.5, 0, -1.4, true)
      );
      this.scene.add(
        new ImageLayer(190, 340, require('@/assets/grasenbloemen3.png'), 7, 2.1, 0, 0.15, true)
      );

      // ornaments
      new OrnamentGroup(this.scene, this);

      // rdisplays
      const offsetBase = 2 * Math.PI,
            offset = offsetBase / Object.keys(this.$store.getters.rituelen()).length;

      let rdisplay,
        yo = 1,
        i = 0;

      for (const [type, ritueel] of Object.entries(this.$store.getters.rituelen())) {
        rdisplay = new RDisplay(type, ritueel, this.monument, i, offsetBase, offset, this,
          (rtype) => {
            // console.log('rdisplay click callback')
            this.$emit("clickedRDisplay", { type: rtype });
          } );

        rdisplay.position( Math.sin(-i * offset) * 70, 6 + yo * 7, Math.cos(-i * offset) * 70 );
        rdisplay.rotateY(-i * offset);

        this.scene.add(rdisplay.obj);

        this.rDisplaysList.push(rdisplay);
        this.monumentCastList.push(rdisplay.obj);

        yo = -yo;
        i++;
      }

      // renderer to dom
      container.innerHTML = '';
      container.appendChild(this.renderer.domElement);
    },

    animate: function () {
      requestAnimationFrame(this.animate);
      !this.doUpdate || this.updateUniverse();
    },

    updateUniverse() {
        this.pcam.update();
        this.renderer.render(this.scene, this.pcam.camera);
    },

    activate() {
      console.log('activate');
      this.doUpdate = true;
    },

    deactivate() {
      console.log('deactivate');
      this.doUpdate = false;
    },

    pause() {
      console.log('pause');
      this.pcam.pause();
    },

    resume() {
      console.log('resume');
      this.pcam.resume();
    },

    setBlur(flag, time) {
      if (!time) {
        time = 510;
      }
      if (flag && flag === true) {
        setTimeout(() => this.$el.classList.add("blur"), time);
      } else if (!flag || flag === false) {
        this.$el.classList.remove("blur");
      }
    },

    updateRDisplayForType(type, ritID) {
      const rdisplay = this.rDisplaysList.find(
        (rdisplay) => rdisplay.type === type
      );
      // console.log(rdisplay);
      !rdisplay || rdisplay.select(ritID);
    },

    onMouseMove(event) {
      if (!this.pcam || !this.pcam.camera || !this.monumentCastList || this.monumentCastList.length === 0) {
        return;
      }

      var mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

      var raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, this.pcam.camera);
      var intersects = raycaster.intersectObjects(this.monumentCastList, true);

      if(intersects.length > 0) {
        document.querySelector("html").style.cursor = 'pointer';
        document.body.style.cursor = 'pointer';
      } else {
        document.querySelector("html").style.cursor = 'default';
        document.body.style.cursor = 'default';
      }
    }
  },

  mounted() {
    this.init();
    this.animate();
  },
};
</script>

<style lang="scss">
@import "../scss/universe";
</style>