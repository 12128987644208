const md5 = require('md5');
const btoa = window.btoa;

export default {

    prepare: (v, r) => {
        const h = 'h' + md5(`h${v}--${r}`);
        v = btoa(v);
        r = btoa(r);

        return { v, r, h }
    }

}