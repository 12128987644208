<template>
  <div class="layer player-end">
    <div v-if="active" class="rows">
      <div class="row top-row">
        <div class="content">
          <div @click="openSendMail" class="inner" v-html="innerText"></div>
        </div>
      </div>
      <div class="row bottom-row">
        <div class="end-banner b-link" :style="`background-image: url(${$store.getters.copy('player_banner_image').url})`" @click="clickOut">
          <tearedSVG class="teared"/>
          <div class="text">{{ $store.getters.copy('player_banner_titel') }}</div>
          <button>{{ $store.getters.copy('button_start') }}</button>
        </div>
        <router-link to="/" class="end-banner b-other" :style="`background-image: url(${otherMonument.afbeelding.url})`">
          <div class="text">{{ $store.getters.copy('player_andere_keuze_titel') }}</div>
          <button>{{ $store.getters.copy('button_ga') }}</button>
        </router-link>
      </div>
      <div class="adopt">
        <a :href="$store.getters.copy('player_adopt_url')" target="_blank" rel="noopener">
          <adoptSVG/>
          <div class="adopt-inner">
            <p v-html="$store.getters.copy('player_adopt_tekst')"></p>
            <div class="buttonContainer">
              <button>{{ $store.getters.copy('button_klik') }}</button>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="mail-container" id="mail-container">
      <div class="mail">
        <form id="mail-form">
          <div class="top">
            <div class="top-cell">
              <label for="f_n">Naam</label>
              <input type="text" name="n" id="f_n" placeholder="Vul je naam in">
            </div>
            <div class="top-cell">
              <label for="f_e">E-mailadres</label>
              <input type="email" name="e" id="f_e" placeholder="Vul je e-mailadres in">
            </div>
          </div>
          <div class="message">
            <label for="f_m">Bericht</label>
            <textarea name="m" id="f_m" rows="4" placeholder="Vul je bericht in"></textarea>
          </div>
          <div class="bottom">
            <div class="error" id="feedback">{{ error }}</div>
            <button @click="sendMail" id="sendBtn">Versturen</button>
          </div>
        </form>
        <img class="close" @click="closeSendMail" :src="require('@/assets/close.svg')" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Mail from '@/util/mail';

import tearedSVG from "@/assets/teared.svg?inline";
import adoptSVG from "@/assets/sticky.svg?inline";

const axios = require('axios');
const validator = require("email-validator");

export default {
  name: "PlayerEnd",

  components: {
    tearedSVG,
    adoptSVG,
  },

  props:{
    monumentID: Number,
  },

  data() {
    return {
      otherMonument: null,
      active: false,
      error: '',
    };
  },

  computed: {
    innerText() {
      const im = require('@/assets/np_mail_1535683_000000.png');
      let t = this.$store.getters.copy('player_end_tekst');
      t = t.replace('{{MAIL_ICON}}', `<img class="mail-icon" src="${im}">`)
      return t;
    }
  },

  methods: {
    show() {
      // console.log('show')
      this.active = true;
      setTimeout(() => this.$el.classList.add("show"), 20);
    },

    hide() {
      this.$el.classList.remove("show");

      this.active = false;

      this.$emit("closed");
    },

    clickOut() {
      window.open(this.$store.getters.copy('player_banner_url'), "_blank");
    },

    openSendMail() {
      document.getElementById('mail-container').classList.add('show');
    },

    closeSendMail() {
      document.getElementById('mail-container').classList.remove('show');
    },

    sendMail(event) {
      event.preventDefault();

      const feedback = document.getElementById('feedback');
      const sendBtn = document.getElementById('sendBtn');

      const n = document.getElementById('f_n');
      const e = document.getElementById('f_e');
      const m = document.getElementById('f_m');

      // clear feedback
      feedback.innerText = 'Bezig met versturen...';
      n.classList.remove('error');
      e.classList.remove('error');
      m.classList.remove('error');

      // disable button
      sendBtn.setAttribute("disabled", "disabled");
      
      if (n.value.length < 1) {
        feedback.innerText = 'Je hebt nog geen naam ingevuld';
        n.classList.add('error');
        n.focus();
        sendBtn.removeAttribute("disabled");
        return;
      }
      
      if (!validator.validate(e.value)) {
        feedback.innerText = 'Het e-mailadres is niet correct';
        e.classList.add('error');
        e.focus();
        sendBtn.removeAttribute("disabled");
        return;
      }
      
      if (m.value.length < 1) {
        feedback.innerText = 'Je hebt nog geen bericht ingevuld';
        m.classList.add('error');
        m.focus();
        sendBtn.removeAttribute("disabled");
        return;
      }

      // post
      axios({
        method: 'post',
        url: (window.API_URL || process.env.VUE_APP_API_URL) + 'mailto',
        data: Mail.prepare(n.value, e.value, m.value)
      })
        .then((response) => {
          // console.log("axios result", response.data);
          
          if (response.data.success) {

            feedback.innerText = 'Bedankt voor je bericht!';

            setTimeout(() => {
                feedback.innerText = '';
                n.value = '';
                e.value = '';
                m.value = '';

                sendBtn.removeAttribute("disabled");
                this.closeSendMail();
            }, 2000)

          } else {

            feedback.innerText = response.data.errorCode;
            sendBtn.removeAttribute("disabled");

          }
        });
    }
  },

  mounted() {
    setTimeout(() => {
      let player_andere_keuze_image = this.$store.getters.copy('player_andere_keuze_image');
      if (player_andere_keuze_image && player_andere_keuze_image.url) {
        this.otherMonument = { afbeelding : player_andere_keuze_image };
      } else {
        this.otherMonument = this.$store.getters.getMonumentExclId(this.monumentID);
      }

    }, 250);
  }
};
</script>