<template>
  <div class="s-container">
    <Logo ref="logo"/>
    <Nav></Nav>
    <router-view @lightsoff="lightsoff"/>
  </div>
</template>

<script>
import Nav from '@/components/Nav';
import Logo from '@/components/Logo';

export default {
  name: "App",

  components: {
    Logo,
    Nav,
  },

  methods: {
    lightsoff() {
      // console.log('Lightsout!')
      this.$refs.logo.$el.classList.remove('show');
      this.$refs.logo.$el.classList.add('hide');
    }
  },

  mounted() {
    const exists = document.querySelector('div.lores');

    if (!exists) {
      let div = document.createElement('div');
      div.classList.add('lores');
      div.innerHTML = '<h2>Hoi! </h2>';
      div.innerHTML += '<p>Deze app is gemaakt om te bekijken op een <em>tablet <span class="light">(liggend)</span> of groter scherm.</em></p>';
      div.innerHTML += '<p class="smaller">Op kleinere schermen zal deze app er mogelijk niet goed uitzien, of zelfs niet goed werken.</p>';
      div.innerHTML += '<div id="loresCloseBtn" class="close"></div>';
      document.body.appendChild(div);
      document.getElementById('loresCloseBtn').addEventListener('click', () => {
        div.classList.add('hide');
      });
    }

    if (this.$route.name === "intro") {
      setTimeout(() => { this.$refs.logo.$el.classList.add('show'); }, 250)
    }

    document.body.classList.add(this.$route.name);
  },

  watch: {
    $route(to, from) {
      // console.log('to', to)
      // console.log('from', from)

      if (to.name !== from.name && to.name === "intro") {
        
        this.$refs.logo.$el.classList.remove('hide');
        this.$refs.logo.$el.classList.add('show');

        if (document.body.classList.contains("lights-off")) {
          document.body.classList.remove("lights-off");
        }
      }
      
      document.body.classList.remove(from.name);
      document.body.classList.add(to.name);
    }
  }
}
</script>