export default {

    IDLE:       'idle',

    CLICKED :   'clicked',

    ACTIVATED : 'activated',

    DEACTIVATED : 'deactivated',

    SLICES :    'slices',

    

}