<template>
  <div :id="`em-${monument.index}`" class="monument">
    <img :src="monument.asset">
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  name: "StaticMonument",

  components: {},

  props: {
    monument: Object,
  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {
  },

  mounted() {
  },
};
</script>