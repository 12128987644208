<template>
    <div class="c-title">
        <div class="text">
        <div class="monument-title">{{ monument.title }}</div>
        <div class="app-title">{{ $store.getters.copy('app_title') }}</div>
        </div>
        <div class="image" :style="`background-image: url(${monument.profiel.url})`"></div>
    </div>
</template>

<script>

export default {
  name: "MonumentTitle",

  props: {
      monument: Object,
  },
};
</script>