<template>
  <div class="progress-bar">
    <div class="pbar">
      <div ref="inner" class="inner"></div>
    </div>
    <div class="progress-btn selected">
      <span class="icon">{{ $store.getters.copy('editbar_verhaal_titel') }}</span>
      <span class="dot"></span>
      <span>{{ $store.getters.copy('editbar_verhaal_label') }}</span>
    </div>
    <div v-for="(ritueel, index) in rituelen" :key="index" :id="`pbtn-${index + 1}`" :class="`progress-btn ${ritueel.type}`" @click="clickHandler" :data-type="ritueel.type">
      <span class="icon">
        <gedichtSVG v-if="ritueel.type === `gedicht`" />
        <muziekSVG v-if="ritueel.type === `muziek`" />
        <stilteSVG v-if="ritueel.type === `stilte`" />
        <wilhelmusSVG v-if="ritueel.type === `wilhelmus`" />
        <bloemenSVG v-if="ritueel.type === `neerleggen`" />
      </span>
      <span class="dot"></span>
      <span>{{ ritueel.term_name }}</span>
    </div>
    <div class="progress-btn" :id="`pbtn-${rituelen.length + 1}`">
      <span class="icon"></span>
      <span class="dot"></span>
      <span>{{ $store.getters.copy('progressbar_einde_label') }}</span>
    </div>
  </div>
</template>

<script>
import gedichtSVG from "@/assets/gedicht.svg?inline";
import muziekSVG from "@/assets/muziek.svg?inline";
import stilteSVG from "@/assets/stilte.svg?inline";
import wilhelmusSVG from "@/assets/wilhelmus.svg?inline";
import bloemenSVG from "@/assets/bloemen.svg?inline";
import checkmarkSVG from "@/assets/checkmark.svg?inline";
import playSVG from "@/assets/play.svg?inline";

export default {
  name: "ProgressBar",

  components: {
    gedichtSVG,
    muziekSVG,
    stilteSVG,
    wilhelmusSVG,
    bloemenSVG,
    checkmarkSVG,
    playSVG,
  },

  props: {
    rituelen: Array,
  },

  computed:{
  },

  methods: {

    show() {
      this.$el.classList.add("show");
    },

    clickHandler() {
      // console.log('clickHandler')
    },

    setBar(step) {
      console.log(`setBar(${step})`)
      this.$refs.inner.style.transform = `scaleX(${step * 1 / (this.rituelen.length + 1)})`;
      const btn = document.getElementById(`pbtn-${step}`);
      !btn || btn.classList.add('selected');
    },
    
  },
};
</script>