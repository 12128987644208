<template>
  <div class="c-intro">
    <canvas id="dots"></canvas>

    <div class="ornaments">
      <div class="ornament boat"></div>
      <div class="ornament boat-waves"></div>
    </div>
    
    <div ref="static_monumenten" v-if="Object.keys(static_monuments).length" class="static_monumenten">
      <StaticMonument v-for="monument in static_monuments" :key="monument.index" :monument="monument" />
    </div>
    
    <div ref="monumenten" class="monumenten" @click="backgroundClickHandler">
      <SelectableMonument ref="monumentList" v-for="(monument, index) in $store.state.monuments" :key="index" :index="index" :monument="monument" @activate="monumentActivateHandler" @openSlices="openSlices"/>
    </div>
    
    <div id="scene1" class="c-text-scene">
      <div id="t1" class="c-text hide" v-html="$store.getters.copy('intro_t1')"></div>
      <div id="t2" class="c-text hide" v-html="$store.getters.copy('intro_t2')"></div>
    </div>
    
    <div id="scene2" class="c-text-scene">
      <div id="t3" class="c-text hide" v-html="$store.getters.copy('intro_t3')"></div>
    </div>

    <div ref="banner" class="banner">
      <div class="inner">
        <div class="text" v-html="$store.getters.copy('app_start_title')"></div>
      </div>
      <button @click="lightsOff">{{ $store.getters.copy('button_start') }}</button>
      <div class="cta">{{ $store.getters.copy('app_start_subtitle') }}</div>
    </div>

    <Rope ref="rope" @pulled="lightsOff"/>
    
    <div v-if="state === 'slices'" ref="embed" class="embed-container">
      <iframe ref="embedIframe" id="embedIframe" class="embed" src="" frameborder="0"></iframe>
      <router-link ref="continueBtn" v-if="activatedMonument !== null" :to="`/editor/${activatedMonument.ID}`" class="verder" @click="clickPostSlicesHandler">{{ $store.getters.copy('slices_fallback_link_text') }}</router-link>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

import StaticMonument from '@/components/StaticMonument';
import SelectableMonument from '@/components/SelectableMonument';
import Rope from '@/components/Rope';

import States from '@/util/states';
import Sound from '@/util/sound';
import Settings from '@/config/settings';

const sndClick      = new Sound("beep-timberl.mp3");
const sndSelect     = new Sound("beep-timberl-sel.mp3");

let c, ctx, drawInt, resizeInt, exposeInt, prevRand;

export default {
  name: "Intro",

  components: {
    StaticMonument,
    SelectableMonument,
    Rope,
  },

  data() {
    return {
      static_monuments: {},
      dotsDrawn: 0,
      state: States.IDLE,
      activatedMonument: null,
      mapCanvas: null,
      mapCanvasImg: null,
      mapContext: null,
      dots_timer: Settings.DOTS_TIMER_INITIAL,
    };
  },

  methods: {
    /**
     * Get a random position on the screen, 
     * based on the mapping canvas
     * A dark pixel means position is valid, 
     * otherwise return a new random position
     */
    getRandomMappedPosition() {
        let x = 1 + Math.random() * (c.width - 2);
        let y = 1 + Math.random() * (c.height - 2);
        const p = this.mapContext.getImageData(x, y, 1, 1).data; 
        if (p[0] < 20 && p[1] > 180 && p[1] < 200 && p[2] > 240) {
          return {x, y};
        } else {
          return this.getRandomMappedPosition();
        }
    },

    /**
     * Draw a dot representation of a monument
     */
    drawDot() {
      // n at once, distributed along mapping canvas 
      for (let i = 0; i < Settings.DOTS_PER_ITERATION; i++) {
        let pos = this.getRandomMappedPosition();
        let opacity = 0.1 + Math.random() * 0.9; 
        ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
        ctx.beginPath();
        ctx.arc(pos.x, pos.y, 0.5 + Math.random() * 2, 0, Math.PI * 2);
        ctx.fill();
      }

      this.dotsDrawn += 3;

      if (this.dotsDrawn < Settings.MAX_DOTS) {
        clearTimeout(drawInt);
        drawInt = setTimeout(this.drawDot.bind(this), this.dots_timer);
      } else {
        // console.log("finished");
      }
    },

    windowResize() {
      clearTimeout(resizeInt);
      resizeInt = setTimeout(this.windowResizeExec.bind(this), 250);
    },

    windowResizeExec() {
      if (c) {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        c.width = vw;
        c.height = vh;
        ctx = c.getContext("2d");

        // Reset mapcanvas
        this.resizeMapCanvas();

        // Reposition static_monuments
        this.positionStaticMonuments();
      }
    },

    positionStaticMonuments() {
        let el;
        Object.keys(this.static_monuments).forEach((key, index) => {
          // reference
          el = document.getElementById(`em-${index}`);
          // get random position within NL
          const pos = Settings.STATIC_MONUMENT_POSITIONS[index];
          let f, a;
          if (c.width >= c.height) {
            f = c.width / 2048;
            a = -0.5 * (f * 1843 - c.height);
            el.style.left = `${f * pos.x}px`;
            el.style.top = `${a + f * pos.y}px`;
          } else {
            f = c.height / 1843;
            a = -0.5 * (f * 2048 - c.width);
            el.style.left = `${a + f * pos.x}px`;
            el.style.top = `${f * pos.y}px`;
          }
        });
    },

    /**
     * Monument is activated, showing iframe
     */
    monumentActivateHandler(activatedMonument) {
      console.log('monumentActivateHandler', activatedMonument.monument);

      sndClick.play();

      this.$refs.monumentList.forEach((monument) => {
        // console.log(monument.index, activatedMonument.index)
        if (monument.index !== activatedMonument.index)
        monument.pushback();
      });
      
      this.stopExpose();

      this.state = States.ACTIVATE;
    },

    /**
     * Open slices presentation
     */
    openSlices(activatedMonument) {
      console.log('openSlices', activatedMonument.monument);

      this.state = States.SLICES;

      sndSelect.play();

      this.activatedMonument = activatedMonument.monument;

      let slices_url = activatedMonument.monument.slices_content;

      if (slices_url.substring(0, 4) !== 'http') {
        slices_url = (window.PUBLIC_PATH || process.env.VUE_APP_PUBLIC_PATH) + 'slices/' + activatedMonument.monument.slices_content;
      }

      slices_url += slices_url.endsWith("/") ? "" : "/";

      // listen to iframe 
      setTimeout(() => {
        this.$refs.embedIframe.onload = () => {
          console.log('loaded iframe')

          const contentDocument = this.$refs.embedIframe.contentDocument || false;

          console.log('contentDocument', contentDocument)

          if (contentDocument) {

            console.log('Slices will automatically trigger app');

            const cookieWarning = this.$refs.embedIframe.contentDocument.getElementById('cookieWarning');
            if (cookieWarning) {
              cookieWarning.style.display = 'none';
              console.log('CookieWarning for slices disabled');
            }

            const app = this.$refs.embedIframe.contentDocument.getElementById('app');

            console.log(app);

            const numSlices = Object.keys(app.storyData.slicesIds).length;

            app.addEventListener('user-navigation', (event) => { 

              console.log('user-navigation', event.detail.currentIndex, numSlices); 

              if (event.detail.currentIndex === numSlices) {
                console.log('reached end of slices');
                this.$router.push(`/editor/${this.activatedMonument.ID}`)
              }
            });
            
          } else {
            console.log('Slices will NOT automatically trigger app');

            !this.$refs.continueBtn || setTimeout(() => this.$refs.continueBtn.$el.classList.add('show'), 2000);
          }
        };
        this.$refs.embedIframe.src = slices_url
      }, 200);

    },

    /**
     * Monuments "unclick"
     */
    backgroundClickHandler(event) {
      if (this.state === States.IDLE) {
        // console.log('idle')
        return;
      }

      if (event.target === this.$refs.monumenten) { // background clicked
        this.$refs.monumentList.forEach((monument) => {
          monument.deactivate();
        });

        this.startExpose();

        this.state = States.IDLE;
      }
    },

    /**
     * Fallback link click handler on slices if not on same domain
     */
    clickPostSlicesHandler() {
      console.log('clickPostSlicesHandler');
      sndSelect.play();
    },

    /**
     * Start expose
     */
    startExpose() {
      console.log('startExpose');
      exposeInt = setTimeout(this.nextExpose.bind(this), 1500);
    },

    /**
     * Stop expose
     */
    stopExpose() {
      console.log('stopExpose');
      clearTimeout(exposeInt);
      this.$refs.monumentList.forEach((monument) => monument.deexpose());
    },

    /**
     * Next expose
     */
    nextExpose() {
      console.log('nextExpose');
      clearTimeout(exposeInt);

      let rand = this.getRand();
      let exposed;
      this.$refs.monumentList.forEach((monument, index) => {
        // console.log(rand, index)
        if (index === rand) {
          monument.expose();
          exposed = monument;
        } else {
          monument.deexpose();
        }
      });

      exposeInt = setTimeout(this.hideExpose.bind(this), 7000, exposed);
    },

    hideExpose(monument) {
      console.log('hideExpose');
      clearTimeout(exposeInt);
      if (monument) {
        monument.deexpose();
      }
      exposeInt = setTimeout(this.nextExpose.bind(this), 1000);
    },

    getRand() {
      let rand = Math.floor(Math.random() * this.$refs.monumentList.length);
      if (rand !== prevRand) {
        return rand;
      }
      return this.getRand();
    },

    /**
     * Start the intro
     */
    startIntro() {
      console.log('startIntro');

      // Prep static_monuments
      let el;
      Object.keys(this.static_monuments).forEach((key, index) => {
        // reference
        el = document.getElementById(`em-${index}`);
        el.querySelector('.dot').style.transitionDelay = `${index * Settings.STATIC_MONUMENTS_TIMER}ms`;
        el.querySelector('img').style.transitionDelay = `${110 + index * Settings.STATIC_MONUMENTS_TIMER}ms`;
        el.classList.add('show');
        // store reference
        this.static_monuments[key].el = el;
      });

      // Position static_monuments
      this.positionStaticMonuments();

      // show gui
      setTimeout(() => {
        // show light switch
        setTimeout(() => this.$refs.rope.start(), 1000);
        // show banner
        this.$refs.banner.classList.add('show');

      }, 1200 + Object.keys(this.static_monuments).length * Settings.STATIC_MONUMENTS_TIMER);

    },

    /** 
     * Turn the lights off and start dots and text animations
     */
    lightsOff() {
        document.body.classList.add('lights-off');

        this.$refs.rope.hide();

        this.$emit('lightsoff');

        this.drawDot();     

        setTimeout(() => {

          // decrease dots_timer
          const target = {
            dots_timer: this.dots_timer
          }

          // 4000 monuments incremental
          anime({
              targets: target,
              dots_timer: Settings.DOTS_TIMER_TARGET,
              duration: 4000,
              easing: 'easeOutCirc',
              update: () => {
                  this.dots_timer = Math.round(target.dots_timer);
              },
          });

          // texts
          this.startTexts();

          // stop rope ticks
          this.$refs.rope.deactivate();

        }, 2000);
    },

    /**
     * Start text animations
     */
    startTexts() {
      let time = 0;

      // Scene 1  
      setTimeout(() => document.getElementById('t1').classList.add('show'), time);
      time += 4000;
      setTimeout(() => document.getElementById('t2').classList.add('show'), time);
      time += 4000;

      // Hide scene 1
      setTimeout(() => document.getElementById('scene1').classList.add('hide'), time);
      time += 500;

      // monument dots
      setTimeout(() => {
        console.log(document.querySelectorAll('.monuments .monument'));
        document.querySelectorAll('.monumenten .monument').forEach((el, index) => {
          setTimeout(() => el.querySelector('.dot').classList.add('big'), index * 400);
        });
      }, time);
      time += 3000;

      // Scene 2
      setTimeout(() => document.getElementById('t3').classList.add('show'), time);
      time += 4000;


      // Hide scene 2
      setTimeout(() => document.getElementById('scene2').classList.add('hide'), time);

      // Icons
      setTimeout(() => {
        document.querySelectorAll('.monumenten .monument').forEach(this.singleMonumentShowHandler.bind(this));

        setTimeout(this.startExpose.bind(this), 1000);
      }, time);
    },

    /**
     * Show a single monument 
     */
    singleMonumentShowHandler(el, index) {
      // console.log('singleMonumentShowHandler', el, index);
      const visual = el.querySelector('.visual');
      setTimeout(() => {
        const targetTransform = `${visual.style.transform} scale(1) rotate(0)`; 
        visual.style.transform += `scale(0) rotate(20deg)`;
        visual.style.visibility = 'visible';
        setTimeout(() => {
          visual.style.transition = 'all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)';
          visual.style.transform = targetTransform;
        }, 100)
      }, index * 200);
    },

    /**
     * Handles turning off the light and continue
     */
    lightswitchHandler() {
      const toggle = document.getElementById('light-switch-toggle')
      if (toggle.checked) {
        toggle.disabled = true;
        this.lightsOff();
      }
    },

    resizeMapCanvas() {
      this.mapCanvas.width = c.width;
      this.mapCanvas.height = c.height;
      let ratio;
      if (c.width >= c.height) {
        ratio = this.mapCanvas.width / this.mapCanvasImg.width;
        this.mapContext.drawImage(this.mapCanvasImg, 
          0, 0, this.mapCanvasImg.width, this.mapCanvasImg.height, 
          0, -0.5 * (this.mapCanvasImg.height * ratio - this.mapCanvas.height), this.mapCanvas.width, this.mapCanvasImg.height * ratio
        );
      } else {
        ratio = this.mapCanvas.height / this.mapCanvasImg.height;
        this.mapContext.drawImage(this.mapCanvasImg, 
          0, 0, this.mapCanvasImg.width, this.mapCanvasImg.height, 
          -0.5 * (this.mapCanvasImg.width * ratio - this.mapCanvas.width), 0, this.mapCanvasImg.width * ratio, this.mapCanvas.height
        );
      }
    },
  },

  created() {
    // require all monuments at build
    const monumentsDir = require.context('@/assets/monumenten', false, /\.png$/);
    monumentsDir.keys().forEach((key, index) => {
      // console.log(key, index);
      this.static_monuments[key] = {
        index: index,
        asset: monumentsDir(key),
      };
    });
  },

  mounted() {
    // console.log(process.env.NODE_ENV === 'development');

    c = document.getElementById("dots");

    if (c && c.getContext) {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      c.width = vw;
      c.height = vh;
      ctx = c.getContext("2d");

      // mapping canvas for dots
      this.mapCanvas = document.createElement('canvas');
      this.mapCanvas.style.top = 0;
      this.mapCanvas.style.left = 0;
      this.mapCanvas.style.position = 'fixed';
      this.mapCanvas.style.backgroundColor = 'green';
      this.mapCanvas.style.display = 'none';
      this.mapContext = this.mapCanvas.getContext("2d");
      document.body.appendChild(this.mapCanvas);

      this.mapCanvasImg = new Image();
      this.mapCanvasImg.onload = () => {
        
        this.resizeMapCanvas();

        /**
         * ------------ DEV SKIP ----------------
         */
        if (Settings.DEV_SKIP_INTRO && process.env.NODE_ENV === 'development') 
        {
          console.log('skip');
          document.body.classList.add('lights-off');
          // this.$refs.lightswitch.classList.add('hide');
          setTimeout(() => this.$emit('lightsoff'), 500);
          document.querySelectorAll('.monumenten .monument').forEach((el, index) => {
            setTimeout(() => el.querySelector('.dot').classList.add('big'), index * 400);
          });
          document.querySelectorAll('.monumenten .monument').forEach(this.singleMonumentShowHandler.bind(this));
          this.startExpose();

        } else {
          setTimeout(() => this.startIntro(), 500);
        }
      };

      this.mapCanvasImg.src = require('@/assets/nl-backdrop.png');
    }

    //
    window.addEventListener("resize", this.windowResize.bind(this));
  },
};
</script>

<style lang="scss">
@import "../scss/intro";
</style>