import * as THREE from "three";

// import * as dat from 'dat.gui';

const UNSELECTED = 0;
const SELECTED = 1;
const SKIPPED = -1;

const TYPE_LAYER = 'layer';
const TYPE_TEXT = 'text';
const TYPE_SELECT = 'select';
const TYPE_CHECKMARK = 'checkmark';
const TYPE_SKIP = 'skip';

const Z_SPACING = 3;
const TEXTURE_MESH_RATIO = 0.05;

class RDisplay {

    index = null;

    type = 'Unnamed';

    ritueel = null;

    elements = [    // [asset, xoffset, yoffset, zoffset, type]
    ];

    state = UNSELECTED;

    universe = null;

    camera = null;

    offset = null;

    monument = null;

    checkmark = null;

    skipcross = null;

    title = null;

    select_icon = null;

    selectedRitueelID = null;

    onclickCallback = null;

    constructor(type, ritueel, monument, index, offsetBase, offset, universe, onclick) {

        this.type = type;

        this.ritueel = ritueel;

        this.monument = monument;

        this.index = index;

        this.offsetBase = offsetBase;

        this.offset = offset;

        this.universe = universe;

        this.camera = universe.pcam.camera;

        this.onclickCallback = onclick;

        this.group = new THREE.Group();

        if (this.monument[`${type}_list`]) {
            this.monument[`${type}_list`].forEach((ritueel, index) => {
                this.elements.push({
                    src: ritueel.rdisplay_image,
                    x: index * 10,
                    y: index * -3,
                    z: this.monument[`${type}_list`].length * Z_SPACING - index * Z_SPACING,
                    type: TYPE_LAYER,
                    scale: ritueel.scale_factor > 0 ? ritueel.scale_factor : 1,
                    ritID: ritueel.ID
                });
            });
        }

        let _index = 0;

        // console.log(type);
        this.elements.push({ text: type, x: -4, y: 2, z: -_index * Z_SPACING, type: TYPE_TEXT, scale: 1 });

        index++;

        // add gui elements
        this.elements.push({ src: require("@/assets/select.png"), x: 0, y: -6, z: -_index * Z_SPACING, type: TYPE_SELECT, scale: 1 });
        this.elements.push({ src: require("@/assets/checkmark.png"), x: -4, y: 2, z: -_index * Z_SPACING, type: TYPE_CHECKMARK, scale: 1 });
        this.elements.push({ src: require("@/assets/close-grey.png"), x: -7, y: -4, z: -_index * Z_SPACING, type: TYPE_SKIP, scale: 1.5 });

        // create layers
        this.elements.forEach((el, i) => {

            let geometry;

            // geom
            if (el.type === TYPE_TEXT) {
                geometry = new THREE.BoxGeometry(30, 5, 0);
            
            } else {
                geometry = new THREE.BoxGeometry(1, 1, 0);
            }

            // mat
            const material = new THREE.MeshBasicMaterial({ transparent: true, opacity: 1 });
            material.alphaTest = 0.02;

            // mesh
            const mesh = new THREE.Mesh(geometry, material);
            mesh.position.x = -el.x;
            mesh.position.y = 0;
            mesh.position.z = i * -Z_SPACING + el.z;

            // pivot point
            const pivot = new THREE.Object3D();
            pivot.position.y = el.y;
            pivot.add(mesh);

            // group 
            this.group.add(pivot);

            if (el.type === TYPE_CHECKMARK) {
                this.checkmark = mesh;
                this.updateCheckmark();
            }

            if (el.type === TYPE_TEXT) {
                this.title = mesh;
            }

            if (el.type === TYPE_SELECT) {
                this.select_icon = mesh;
            }

            if (el.type === TYPE_SKIP) {
                this.skipcross = mesh;
                this.updateSkipcross();
            }

            // store reference
            this.elements[i].mesh = mesh;

            // load texture on non text type
            if (el.type !== TYPE_TEXT) {
                const loader = new THREE.TextureLoader();
                loader.load(el.src,
                    (texture) => {
                        texture.format = THREE.RGBAFormat;
                        texture.magFilter = THREE.LinearFilter;
                        material.map = texture;
                        // scale according to texture ratio
                        geometry.scale(texture.image.width * TEXTURE_MESH_RATIO * el.scale, texture.image.height * TEXTURE_MESH_RATIO * el.scale, 1);
                        // position according to texture height
                        mesh.position.y = -texture.image.height * TEXTURE_MESH_RATIO;
                        pivot.position.y = texture.image.height * TEXTURE_MESH_RATIO + el.y;

                        // update material
                        material.needsUpdate = true;
                    }
                );
            }
            
            // create texture on type text
            if (el.type === TYPE_TEXT) {
                const canvas = document.createElement('canvas');
                canvas.width = 600;
                canvas.height = 100;
                const ctx = canvas.getContext("2d");
                document.body.appendChild(canvas);
                // console.log(el.text);
                
                var f = new FontFace("Londrina", "url(https://fonts.gstatic.com/s/londrinasolid/v10/flUhRq6sw40kQEJxWNgkLuudGfNeKBM.woff2)");
                f.load().then((font) => {
                    document.fonts.add(font);
                    // ctx.fillStyle = '#ff9900';
                    // ctx.fillRect(0, 0, 600, 100);
                    ctx.font = "6em Londrina";
                    ctx.fillStyle = '#00203A';
                    ctx.fillText(this.ritueel.titel, 15, 81);
                    ctx.fillStyle = '#FFF500';
                    ctx.fillText(this.ritueel.titel, 12, 78);
                    // set texture
                    material.map = new THREE.CanvasTexture(canvas);
                    // update material
                    material.needsUpdate = true;
                    
                    document.body.removeChild(canvas);
                });

            }
        });

        // interaction
        if (this.onclickCallback) {
            this.group.on('mousedown', this.clickstart.bind(this));
            this.group.on('mouseup', this.clickend.bind(this));
            this.group.on('touchstart', this.clickstart.bind(this));
            this.group.on('touchend', this.clickend.bind(this));
        }

    }

    get obj() {
        return this.group;
    }

    clickstart(event) {
        // console.log(event, this, this.onclickCallback, this.camera);
        this.universe.pause();
        this.rotated = { x: this.camera.rotation.x, y: this.camera.rotation.y, z: this.camera.rotation.z };
    }

    clickend(event) {
        // console.log('clickend', this.rotated, this.camera, Math.abs(this.rotated.x - this.camera.rotation.x), Math.abs(this.rotated.y - this.camera.rotation.y), Math.abs(this.rotated.z - this.camera.rotation.z));
        if (this.rotated && this.camera
            && Math.abs(this.rotated.x - this.camera.rotation.x) < 0.01
            && Math.abs(this.rotated.y - this.camera.rotation.y) < 0.01
            && Math.abs(this.rotated.z - this.camera.rotation.z) < 0.01
        ) {
            // console.log('clickend calling callback', this.onclickCallback);
            this.onclickCallback.call(this, this.type);
        } else {
            this.universe.resume();
        }

    }

    position(x, y, z) {
        this.group.position.x = x;
        this.group.position.y = y;
        this.group.position.z = z;
    }

    rotateY(rot) {
        this.group.rotateY(rot);
    }

    select(ritID) {
        // console.log(ritID);
        this.selectedRitueelID = ritID;

        this.elements.forEach(elem => {
            // set visible 
            elem.mesh.visible = !elem.ritID || elem.ritID === ritID;
            // set position for selection (all to middle)
            if (this.state === UNSELECTED) {
                elem.mesh.position.x = 0;
            }
        });

        this.state = ritID == -1 ? SKIPPED : SELECTED;

        this.updateCheckmark();
        this.updateSelect();
        this.updateSkipcross();
        this.updateTitle();
    }

    updateSkipcross() {
        if (this.skipcross) {
            this.skipcross.visible = this.state === SKIPPED;
        }
    }

    updateCheckmark() {
        if (this.checkmark) {
            this.checkmark.visible = this.state === SELECTED;
            this.checkmark.position.x = 17;
            this.checkmark.position.y -= 0;
            this.checkmark.position.z = this.title.position.z;
        }
    }

    updateSelect() {
        if (this.select_icon) {
            this.select_icon.visible = this.state === SELECTED;
            // this.elements.push({ src: require("@/assets/select.png"), x: 0, y: -6, z: -_index * Z_SPACING, type: TYPE_SELECT, scale: 1 });
            const loader = new THREE.TextureLoader();
            const color = this.selectedRitueelID == -1 ? 'grey' : 'green';
            loader.load(require(`@/assets/select-${color}.png`),
                (texture) => {
                    texture.format = THREE.RGBAFormat;
                    texture.magFilter = THREE.LinearFilter;
                    this.select_icon.material.map = texture;
                    this.select_icon.material.needsUpdate = true;
                }
            );
        }
    }

    updateTitle() {
        if (this.title) {
            const canvas = document.createElement('canvas');
            canvas.width = 600;
            canvas.height = 100;
            const ctx = canvas.getContext("2d");
            document.body.appendChild(canvas);
            // console.log(el.text);

            var f = new FontFace("Londrina", "url(https://fonts.gstatic.com/s/londrinasolid/v10/flUhRq6sw40kQEJxWNgkLuudGfNeKBM.woff2)");
            f.load().then((font) => {
                document.fonts.add(font);
                ctx.font = "6em Londrina";
                ctx.fillStyle = '#00203A';
                ctx.fillText(this.ritueel.titel, 15, 81);
                ctx.fillStyle = this.selectedRitueelID == -1 ? '#666666' : '#0AFF00';
                ctx.fillText(this.ritueel.titel, 12, 78);
                // set texture
                this.title.material.map = new THREE.CanvasTexture(canvas);
                // update material
                this.title.material.needsUpdate = true;
                
                document.body.removeChild(canvas);
            });
        }
    }
}

export default RDisplay;