<template>
  <div :class="`ritueel ritueel-${typeClass}`">

    <!-- TITLE not display + not gedicht -->
    <div v-if="!isDisplay && ritueel.type !== 'gedicht'" class="rit-title">{{ ritueel.title }}</div>
    
    <!-- TITLE not display + gedicht -->
    <div v-if="!isDisplay && ritueel.type === 'gedicht'" class="rit-title rit-gedicht">
      <div class="rit-title-type">
        {{ ritueel.term_name }}
      </div>
      <div class="rit-title-auteur">
        Door<br>{{ ritueel.auteur }}
      </div>
    </div>

    <!-- IMAGE not display + not gedicht -->
    <div v-if="!isDisplay && ritueel.type !== 'gedicht'" class="rit-image">
      <img :src="ritueel.rdisplay_image">
      <playSVG v-if="hasContent" class="play" @click="playRitueelHandler"/>
    </div>

    <!-- IMAGE not display + gedicht -->
    <div v-if="!isDisplay && ritueel.type === 'gedicht'" class="rit-image">
      <div class="text">
        <paperSVG class="paper"/>
        <span v-html="ritueel.gedicht_intro"></span>
      </div>
      <audioSVG class="audio" @click="playRitueelHandler"/>
    </div>
    
    <!-- IMAGE display + not gedicht -->
    <!-- <img v-if="isDisplay && ritueel.type !== 'gedicht'" :src="ritueel.rdisplay_image"> -->
    <img v-if="isDisplay" :src="ritueel.rdisplay_image">
    
    <!-- IMAGE display + gedicht -->
    <!-- <paperSVG v-if="isDisplay && ritueel.type === 'gedicht'" class="display-paper"/> -->

    <!-- BUTTON not display -->
    <button v-if="!isDisplay" @click="selectRitueelHandler">{{ $store.getters.copy('button_kiezen') }}</button>
  </div>
</template>

<script>
import paperSVG from "@/assets/paper.svg?inline";
import playSVG from "@/assets/play3.svg?inline";
import audioSVG from "@/assets/audio.svg?inline";

export default {
  name: "RitueelItem",

  props: {
    ritueel: Object,
    isDisplay: Boolean,
  },

  components: {
    audioSVG,
    paperSVG,
    playSVG,
  },

  computed: {
    hasContent() {
      return this.ritueel.heeft_video 
        || this.ritueel.heeft_geluidsfragment
        || this.ritueel.heeft_tekst
        || this.ritueel.heeft_afbeelding;
    },

    typeClass() {
      return this.ritueel ? this.ritueel.type : '';
    },
  },

  methods: {
    selectRitueelHandler(event) {
      this.$emit("selectRitueel", this.ritueel);
    },

    playRitueelHandler(event) {
      this.$emit("playRitueel", this.ritueel);
    },
  },

  // mounted() {
  //   console.log(this.ritueel);
  // }
};
</script>