import './util/public-path'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const axios = require('axios');

Vue.config.productionTip = false;
Vue.config.devtools = true;

import '@/scss/style.scss';
import Settings from './config/settings';

console.log( `%c Herdenken ${process.env.VUE_APP_VERSION}-${process.env.VUE_APP_VERSION_POSTFIX} %c %c Studio Parkers (*v*) %c %c ${window.PUBLIC_PATH || process.env.VUE_APP_PUBLIC_PATH} `, 
  'background: #FED81D; color: #000102; padding: 2px; font-weight: bold', 
  'background: none; padding: 2px', 
  'background: #000; color: #00cc33; padding: 2px', 
  'background: none; padding: 2px', 
  'background: #00c; color: #fff; padding: 2px' 
);

console.groupCollapsed("API");
console.log( window.API_URL || process.env.VUE_APP_API_URL );
console.log( window.NAV_URL || process.env.VUE_APP_NAV_URL );
console.groupEnd();

axios.get((window.API_URL || process.env.VUE_APP_API_URL) + 'herdenken')
  
  .then((response) => {
    
    // rituelen
    store.commit("setRituelen", response.data.rituelen);

    // verhalen
    let verhalenList;

    /**
     * DEV: if less than 5, fill
     */
    if (process.env.NODE_ENV === 'development') {
      verhalenList = [].concat(JSON.parse(JSON.stringify(response.data.verhalen)));
      // console.log(response.data.verhalen.length)
      if (response.data.verhalen.length < 5) {
        const num = 5 - response.data.verhalen.length;
        for (let i = 0; i < num; i++) {
          // console.log(i%response.data.verhalen.length)
          verhalenList = verhalenList.concat(JSON.parse(JSON.stringify(response.data.verhalen[i%response.data.verhalen.length])));
        }
      }

    } else {
      verhalenList = JSON.parse(JSON.stringify(response.data.verhalen))
    }
    
    const f = window.innerWidth / 2048;
    const a = -0.5 * (f * 1843 - window.innerHeight);
    
    verhalenList.forEach((el, index) => {
      verhalenList[index].xpos = 100 * (Settings.MONUMENT_POSITIONS[index].x * f) / window.innerWidth;
      verhalenList[index].ypos = 100 * (Settings.MONUMENT_POSITIONS[index].y * f + a) / window.innerHeight;
      verhalenList[index].vxpos = Settings.MONUMENT_POSITIONS[index].vx;
      verhalenList[index].vypos = Settings.MONUMENT_POSITIONS[index].vy;
      verhalenList[index].aw = Settings.MONUMENT_POSITIONS[index].aw;
      verhalenList[index].ah = Settings.MONUMENT_POSITIONS[index].ah;
      verhalenList[index].ar = Settings.MONUMENT_POSITIONS[index].ar;
      verhalenList[index].ax = Settings.MONUMENT_POSITIONS[index].ax;
      verhalenList[index].ay = Settings.MONUMENT_POSITIONS[index].ay;
    });

    store.commit("setMonuments", verhalenList);

    // copy
    store.commit("updateCopy", response.data.options);
    
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  
  .catch((error) => {
    console.warn(error);
    setTimeout(() => {
      location.href = location.href;
    }, 5000);
  });

