import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {

    monuments: [],

    copy: {},

    system: {
      aantalMap : ["nul", "een", "twee", "drie", "vier", "vijf", "zes", "zeven", "acht", "negen", "tien"],
    }
  },

  mutations: {
    setRituelen: (state, rituelen) => (state.rituelen = rituelen),
    
    setMonuments: (state, monuments) => (state.monuments = monuments),

    updateCopy(state, options) {
      for (const [key, value] of Object.entries(options)) {
        state.copy[key] = value;
        // if (state.copy[key]) {
        //   console.log('value found, updated!');
        // } else {
        //   console.log(`NEW ${key}: ${value}`);
        // } 
      }
    }
  },

  actions: {
  },

  modules: {
  },

  getters: {
    getMonumentById: (state) => (ID) => {
      return state.monuments.find(monument => monument.ID == ID);
    },

    getMonumentExclId: (state, getters) => (ID) => {
      let monument = state.monuments[Math.floor(Math.random() * state.monuments.length)];
      // console.log(monument.ID, ID)
      return monument.ID !== ID ? monument : getters.getMonumentExclId(ID);
    },
    
    rituelen: (state) => () => {
      return state.rituelen;
    },
    
    ritueel: (state) => (type) => {
      return state.rituelen[type];
    },

    copy: (state) => (id, monument) => {
      let result = state.copy[id];
      // console.log(id, result, monument);
      
      if (typeof result === "undefined") {
        return "* copy not found *";
      }

      // console.log("result", result);
      
      if (typeof result === "string") {
        // aantal
        result = result.replace('{{AANTAL_TEKST}}', state.system.aantalMap[state.monuments.length])
  
        // verhaal titel
        !monument || ( result = result.replace('{{VERHAAL_TITEL}}', monument.title) );
      }

      return result;
    },
  }
});
