import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import anime from 'animejs/lib/anime.es.js';

let INT_TIME = 0;

class PCam {

    camera = null;

    isPaused = false;

    // waitForRotateSwitch = false;

    rotSpeed = 0;

    prevRot = 0;

    previousLookatIndex = 0;

    constructor(renderer) {
        this.camera = new THREE.PerspectiveCamera(
            35,
            container.clientWidth / container.clientHeight,
            1,
            10000
        );
        this.camera.position.z = -20;

        const minPolar = 1.42;
        const maxPolar = 1.75;
        const centerPolar = minPolar + 0.5 * (maxPolar - minPolar);

        this.controls = new OrbitControls(this.camera, renderer.domElement);
        this.controls.autoRotate = true;
        this.controls.autoRotateSpeed = 0.2;
        this.controls.enablePan = false;
        this.controls.enableZoom = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.07;
        this.controls.rotateSpeed = -0.2;
        // this.controls.minAzimuthAngle = 0.85;
        // this.controls.maxAzimuthAngle = -3.00;
        this.controls.minPolarAngle = centerPolar;
        this.controls.maxPolarAngle = centerPolar; // higher is lower view angle
        this.controls.update();

        this.controls.minPolarAngle = minPolar; // bottom
        this.controls.maxPolarAngle = maxPolar; // top 
        this.controls.update();
    }

    lookAt(rdisplay, callback) {
        // console.log('lookat')
        const dist = Math.abs(rdisplay.index - this.previousLookatIndex);

        const origin = {
            minAzimuthAngle: this.controls.minAzimuthAngle,
            maxAzimuthAngle: this.controls.maxAzimuthAngle,
            minPolarAngle: this.controls.minPolarAngle,
            maxPolarAngle: this.controls.maxPolarAngle,
        };

        const current = { 
            azimuth: this.controls.getAzimuthalAngle(), 
            polar: this.controls.getPolarAngle() 
        };

        anime({
            targets: current,
            polar: 1.55,
            azimuth: Math.PI - rdisplay.index * rdisplay.offset,
            duration: 500,
            easing: dist >= 3 ? 'easeOutQuart' : 'easeOutCubic',
            update: (anim) => {
                this.controls.minAzimuthAngle = current.azimuth;
                this.controls.maxAzimuthAngle = current.azimuth;
                this.controls.minPolarAngle = current.polar;
                this.controls.maxPolarAngle = current.polar;
                this.controls.update();
            },
            complete: (anim) => {
                this.controls.minAzimuthAngle = origin.minAzimuthAngle;
                this.controls.maxAzimuthAngle = origin.maxAzimuthAngle;
                this.controls.minPolarAngle = origin.minPolarAngle;
                this.controls.maxPolarAngle = origin.maxPolarAngle;
                this.controls.update();
                if (callback) {
                    console.log('callback after anim')
                    callback.call(this);
                }
            }
        });
    }

    update() {
        this.controls.update();
    }

    pause() {
        this.controls.autoRotate = false;
        this.isPaused = true;
    }

    resume() {
        this.controls.autoRotate = true;
        this.isPaused = false;
    }
}

export default PCam;